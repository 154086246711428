import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { DESIGN_STATUS, PROJECT_TYPES, TIER_LEVEL } from '@cpm/scanifly-shared-data';
import {
  DESIGN_OPTIONS,
  DESIGN_OPTIONS_FOR_LARGE_PROJECTS,
  LARGE_UPGRADE_OPTIONS,
  PRELIM_DESIGN_OPTIONS,
  PRELIM_DESIGN_OPTIONS_WITH_PLANSET,
  SITEPLAN_UPGRADE_DESIGN_OPTIONS,
  TRUE_UP_UPGRADE_DESIGN_OPTIONS,
  WIREFRAME_UPGRADE_DESIGN_OPTIONS,
} from 'screens/DesignServices/constants';
import { ServiceRequest } from 'types';

import styled from 'styled-components';

import { companyActiveSubscriptionRequested } from 'state/slices/admin/adminSubscriptionsSlice';
import { AppDispatch, RootState } from 'state/store/store';

import { ACCESS } from 'helpers/constants';
import breakpoints from 'helpers/constants/breakpoints';
import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';
import fontWeights from 'helpers/constants/fontWeights';
import useFeatureToggle from 'helpers/hooks/useFeatureToggle';

import { camelCase } from 'lodash';
import SelectDesignButton from '../SelectDesignButton/SelectDesignButton';

const SubtitleWrapper = styled.div`
  color: ${colors.darkGray};
  font-size: ${fontSizes.small};
  margin-left: 0.5rem;
  margin-bottom: 1rem;
`;

const TitleWrapper = styled.div`
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.large};
  margin-right: 1rem;
  margin: 1rem 0 0 0.5rem !important;
  @media (max-width: ${breakpoints.lg}) {
    font-size: ${fontSizes.medium};
  }
`;

type SelectDesignOptionsProps = {
  selected: { tierLevel: TIER_LEVEL; isExpedited: boolean } | null;
  handleSelection: (serviceType: { tierLevel: TIER_LEVEL; isExpedited: boolean }) => void;
  setSelectedDesignInfo: (designInfo: Partial<ServiceRequest> & { projectId?: string }) => void;
  projectId: string;
  upgrade?: boolean;
  actionButtons?: JSX.Element;
};

const SelectDesignOptions = ({
  selected,
  handleSelection,
  setSelectedDesignInfo,
  projectId,
  upgrade,
  actionButtons: ActionButtons,
}: SelectDesignOptionsProps) => {
  const { serviceRequests } = useSelector((state: RootState) => state.designServiceRequests);
  const { project } = useSelector((state: RootState) => state.project);
  const { activeSubscription } = useSelector((state: RootState) => state.adminSubscriptions);
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const designServicesPrelimAccess = useFeatureToggle(ACCESS.DESIGN_SERVICES_PRELIM);

  const prelimAccess =
    useFeatureToggle(ACCESS.REMOTE_DESIGN) || activeSubscription?.canUseRemoteDesign;

  const hasPrelimPlanSetAccess = useFeatureToggle(ACCESS.DESIGN_SERVICES_PRELIM_PLANSETS);

  useEffect(() => {
    if (!activeSubscription && project?.companyId) {
      dispatch(companyActiveSubscriptionRequested(project.companyId));
    }
  }, [project?.companyId, dispatch, activeSubscription]);

  useEffect(() => {
    if (selected) {
      setSelectedDesignInfo({
        projectId,
        tierLevel: selected.tierLevel,
        status: DESIGN_STATUS.ordered,
        isExpedited: selected?.isExpedited ?? false,
      });
    }
  }, [projectId, selected, setSelectedDesignInfo]);

  const hasTrueUpRequests = serviceRequests
    ? serviceRequests.filter(
        (r: ServiceRequest) =>
          r.tierLevel === TIER_LEVEL.trueUp && r.status !== DESIGN_STATUS.canceled
      ).length
    : 0;

  const hasSitePlanRequests = serviceRequests
    ? serviceRequests.filter(
        (r: ServiceRequest) =>
          r.tierLevel === TIER_LEVEL.sitePlan && r.status !== DESIGN_STATUS.canceled
      ).length
    : 0;

  const designOptions = useMemo(() => {
    if (project?.type === PROJECT_TYPES.LARGE) {
      if (upgrade) {
        return LARGE_UPGRADE_OPTIONS;
      }
      return DESIGN_OPTIONS_FOR_LARGE_PROJECTS;
    }
    if (upgrade) {
      if (hasSitePlanRequests) {
        return SITEPLAN_UPGRADE_DESIGN_OPTIONS;
      }
      if (hasTrueUpRequests) {
        return TRUE_UP_UPGRADE_DESIGN_OPTIONS;
      }
      return WIREFRAME_UPGRADE_DESIGN_OPTIONS;
    }
    return DESIGN_OPTIONS;
  }, [hasSitePlanRequests, hasTrueUpRequests, project?.type, upgrade]);

  return designServicesPrelimAccess && prelimAccess ? (
    <>
      {!upgrade ? (
        <>
          <div className="SelectDesignButton-ServiceGroupTitle">
            {t('DesignService.preliminary')}
          </div>
          {(hasPrelimPlanSetAccess
            ? PRELIM_DESIGN_OPTIONS_WITH_PLANSET
            : PRELIM_DESIGN_OPTIONS
          ).map((option) => {
            return (
              <SelectDesignButton
                option={option}
                selected={selected}
                handleSelect={handleSelection}
                key={`${option.serviceType}-${option.isExpedited ? 'expedited' : 'nonexpedited'}`}
                hasNewTag={option?.hasNewTag ?? false}
                isExpedited={option.isExpedited ?? false}
                id={`${camelCase(option.serviceType)}-${option.isExpedited ? 'expedited' : 'nonexpedited'}`}
              />
            );
          })}
        </>
      ) : null}

      <TitleWrapper>{t('DesignService.onsite')}</TitleWrapper>
      <SubtitleWrapper>
        <ExclamationCircleFilled /> {t('OnsiteServiceRequestSubtitle')}
      </SubtitleWrapper>
      {designOptions.map((option) => {
        return (
          <SelectDesignButton
            option={option}
            selected={selected}
            handleSelect={handleSelection}
            hasNewTag={option?.hasNewTag ?? false}
            key={option.serviceType}
            id={camelCase(option.serviceType)}
          />
        );
      })}
      {ActionButtons}
    </>
  ) : (
    <>
      <TitleWrapper>{t('DesignService.onsite')}</TitleWrapper>
      <SubtitleWrapper>
        <ExclamationCircleFilled /> {t('OnsiteServiceRequestSubtitle')}
      </SubtitleWrapper>
      {designOptions.map((option) => {
        return (
          <SelectDesignButton
            option={option}
            selected={selected}
            handleSelect={handleSelection}
            hasNewTag={option?.hasNewTag ?? false}
            key={option.serviceType}
            id={camelCase(option.serviceType)}
          />
        );
      })}
      {ActionButtons}
    </>
  );
};

export default SelectDesignOptions;
