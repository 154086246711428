import { adminFetchAccessTokens } from 'api/admin/adminService';

import { PROJECT_LIST_SIZE } from 'helpers/constants/projectListSize';
import { openNotification } from 'helpers/utils/openNotification';

import { COPY_TO_CLIPBOARD_MESSAGE } from '../constants';

export const handleCopyClick = (text) => {
  navigator.clipboard.writeText(text);
  openNotification({
    type: 'success',
    title: 'Success!',
    text: COPY_TO_CLIPBOARD_MESSAGE(`Token prefix ${text}`),
  });
};

export const handleAccessTokenCopyClick = (accessToken, selectedCompany) => {
  navigator.clipboard.writeText(accessToken);
  openNotification({
    type: 'success',
    title: 'Access Token Copied!',
    text: COPY_TO_CLIPBOARD_MESSAGE(`The access token created for ${selectedCompany?.label}`),
  });
};

export const fetchAccessTokens = async (
  pageIndex,
  searchTerm,
  setIsDataLoading,
  setTotalCount,
  setTableData
) => {
  try {
    setIsDataLoading(true);
    const response = await adminFetchAccessTokens(pageIndex, PROJECT_LIST_SIZE, searchTerm);
    setTotalCount(response.data.totalCount);
    setTableData(response.data.items);
    setIsDataLoading(false);
  } catch (error) {
    openNotification({
      type: 'error',
      title: 'There was an error loading access tokens.',
      text: 'Please try again in a few seconds.',
    });
    setIsDataLoading(false);
  }
};
