import {
  createInverterSettingsSection,
  createShadeSettingsSection,
  createSimulationSettingsSection,
  SettingsShell,
} from '@cpm/settings-ui';

import { settingsApi as api } from 'state/slices/settingsApiSlice';

import { GoBackButton } from 'components';

import { ACCESS } from 'helpers/constants';
import useFeatureToggle from 'helpers/hooks/useFeatureToggle';
import usePermissions from 'helpers/hooks/usePermissions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonContainer, Container, RelativeDiv, Shell, Wrapper } from './components';

type Settings = React.ComponentProps<typeof SettingsShell>['settings'];

const Scanifly3dSettings = () => {
  const { isAdmin: userIsAdmin, isSalesManager, isQa: inverterLibraryEnabled } = usePermissions();
  const monthlyLossesEnabled = useFeatureToggle(ACCESS.SIMULATION_SETTINGS_MONTHLY_LOSSES);
  const { t } = useTranslation();
  const settings: Settings = [
    { title: t('PageTitles.scanifly3dSettings'), type: 'header' },
    createShadeSettingsSection({ api }),
    ...(userIsAdmin || isSalesManager
      ? [
          { title: t('PageTitles.companySettings'), type: 'header' } as const,
          createSimulationSettingsSection({
            api,
            featureFlags: {
              selectMonthlyShadeLossesEnabled: () => monthlyLossesEnabled,
            },
          }),
          ...(inverterLibraryEnabled && (userIsAdmin || isSalesManager)
            ? [
                { title: t('PageTitles.scanifly3dComponents'), type: 'header' } as const,
                createInverterSettingsSection({ api }),
              ]
            : []),
        ]
      : []),
  ];

  return (
    <Wrapper>
      {/* Little container div to respect padding of Wrapper */}
      <RelativeDiv>
        <ButtonContainer>
          <GoBackButton />
        </ButtonContainer>
      </RelativeDiv>
      <Container>
        <Shell settings={settings} config={{ zIndex: 1 }} />
      </Container>
    </Wrapper>
  );
};

export default Scanifly3dSettings;
