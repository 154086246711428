import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import PropTypes from 'prop-types';

const GuardedRoute = ({ children, auth, redirectUrl, ...rest }) => {
  const { isUserLoading } = useSelector((state) => state.users);
  const { isLoggedIn } = useSelector((state) => state.auth);

  if (isLoggedIn && isUserLoading) return null;

  return <Route {...rest} render={() => (auth ? children : <Redirect to={redirectUrl} />)} />;
};

GuardedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  auth: PropTypes.bool.isRequired,
  redirectUrl: PropTypes.string.isRequired,
};

export default GuardedRoute;
