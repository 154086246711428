import { i18n } from 'helpers/utils/translations';

export const BUTTON = {
  NEXT: i18n.t('buttonTexts.next'),
  BACK: i18n.t('buttonTexts.back'),
  CONTINUE: i18n.t('buttonTexts.continue'),
  SUBMIT_ORDER: i18n.t('buttonTexts.submitOrder'),
  SUBMIT_REQUEST: i18n.t('buttonTexts.submitRequest'),
};

export const SUBFORM_TITLES = {
  CUSTOMER_INFORMATION: i18n.t('DSSubformTitles.customerInfo'),
  PROJECT_INFORMATION: i18n.t('DSSubformTitles.projectInfo'),
  STRUCTURAL_INFORMATION: i18n.t('DSSubformTitles.structuralInfo'),
  ELECTRICAL_INFORMATION: i18n.t('DSSubformTitles.electricalInfo'),
  ADDITIONAL_INFORMATION: i18n.t('DSSubformTitles.additionalInfo'),
  PRELIMINARY_DESIGN: i18n.t('DSSubformTitles.preliminaryDesign'),
  DESIGN_INFORMATION: i18n.t('DSSubformTitles.designInfo'),
  TRUE_UP: i18n.t('DSSubformTitles.trueUp'),
  REFERENCE_DESIGN: i18n.t('DSSubformTitles.referenceDesign'),
};

export const SITE_PLAN_FORMS = [
  { step: 1, title: SUBFORM_TITLES.PROJECT_INFORMATION },
  { step: 2, title: SUBFORM_TITLES.ADDITIONAL_INFORMATION },
];

export const fireSetbackInitialData = {
  ridge: '',
  eave: '',
  rake: '',
  valley: '',
  hip: '',
};

export const obstructionSetbackInitialData = {
  vents: '',
  exhaust: '',
  skylights: '',
  parapet: '',
  general: '',
};

export const FORM_CONTROLS = {
  //Project info
  SETBACKS: 'setbacks',
  VIEWSHEDS: 'viewsheds',
  AHJ: 'ahj',
  SYSTEM_SIZE: 'systemSize',
  ELECTRICAL_EQUIPMENT_DESCRIPTION: 'electricalEquipmentDescription',
  HOMEOWNER_NAME: 'homeownerName',
  UTILITY_COMPANY: 'utilityCompany',
  PROJECT_ADDRESS: 'projectAddress',
  INVERTER_COUNT: 'inverterCount',
  SIZED_FOR: 'sizedFor',
  SIZED_FOR_CATEGORY: 'sizedForCategory',
  SIZED_FOR_VALUE: 'sizedForValue',
  NEGATIVE_TOLERANCE: 'negativeTolerance',
  POSITIVE_TOLERANCE: 'positiveTolerance',

  //structural info
  ROOFING_TYPE: 'roofingType',
  ATTACHMENT: 'attachment',
  RACKING: 'racking',
  CENTER_SPACING: 'centerSpacing',
  RAFTER_SIZE: 'rafterSize',
  MAX_SPACING: 'maxSpacing',

  //electrical info
  MAIN_BREAKER_RATING: 'mainBreakerRating',
  MSP_ONSITE: 'mspOnsite',
  MODULE_INFO: 'moduleInfo',
  BATTERY_INFO: 'batteryInfo',
  AC_DISCONNECT: 'acDisconnect',
  MAIN_BUSBAR_RATING: 'mainBusbarRating',
  METER_LOCATION: 'meterLocation',
  INVERTER_INFO: 'inverterInfo',
  BATTERY_COUNT: 'batteryCount',
  PV_REQUIRED: 'pvRequired',
  INTERCONNECTION_METHOD: 'preferredInterconnectionMethod',

  METER_NUMBER: 'meterNumber',
  STORY_COUNT: 'storyCount',
  SUBPANEL_BUSBAR_RATING: 'subPanelBusbarRating',
  SUBPANEL_BREAKER_RATING: 'subPanelBreakerRating',
  CONDUIT_ROUTE: 'preferredConduitRoute',
  COMMENTS: 'comments',
  NEW_BUILD_PLANS_REQUIRED: 'newBuildPlansRequired',
  SELECTED_DESIGN: 'selectedDesignToUse',
  DESIGN_URL: 'selectedDesignUrl',
  COMPANY_ADDRESS: 'companyAddress',
  FIRE_SETBACKS: 'fireSetbacks',
  OBSTRUCTION_SETBACKS: 'obstructionSetbacks',
  DESIGN_ONE_MODULE: 'designOneModule',
  DESIGN_TWO_MODULE: 'designTwoModule',
  MODULE_SPACING_LONG: 'moduleSpacingLong',
  MODULE_SPACING_SHORT: 'moduleSpacingShort',
  AZIMUTH_RANGE_START: 'azimuthRangeStart',
  AZIMUTH_RANGE_END: 'azimuthRangeEnd',
  MODULE_ORIENTATION: 'moduleOrientation',
  MIXED_ORIENTATION: 'mixedOrientation',
  PREFERRED_MODULE_TILT: 'preferredModuleTilt',
  TSRF_MINIMUM: 'tsrfMinimum',
} as const;

export const initialValuesForPlanSet = {
  [FORM_CONTROLS.HOMEOWNER_NAME]: '',
  [FORM_CONTROLS.UTILITY_COMPANY]: '',
  [FORM_CONTROLS.AHJ]: '',

  [FORM_CONTROLS.ROOFING_TYPE]: '',
  [FORM_CONTROLS.ATTACHMENT]: '',
  [FORM_CONTROLS.RACKING]: '',
  [FORM_CONTROLS.CENTER_SPACING]: '',
  [FORM_CONTROLS.RAFTER_SIZE]: '',
  [FORM_CONTROLS.MAX_SPACING]: '',

  [FORM_CONTROLS.MAIN_BUSBAR_RATING]: '',
  [FORM_CONTROLS.MAIN_BREAKER_RATING]: '',
  [FORM_CONTROLS.BATTERY_COUNT]: '',
  [FORM_CONTROLS.COMPANY_ADDRESS]: '',
  [FORM_CONTROLS.FIRE_SETBACKS]: fireSetbackInitialData,
  [FORM_CONTROLS.OBSTRUCTION_SETBACKS]: obstructionSetbackInitialData,
  [FORM_CONTROLS.PROJECT_ADDRESS]: '',
  [FORM_CONTROLS.SIZED_FOR_CATEGORY]: 'none',
  [FORM_CONTROLS.SIZED_FOR_VALUE]: '',
  [FORM_CONTROLS.INTERCONNECTION_METHOD]: '',
};

export const initialValuesForTrueUp = {
  [FORM_CONTROLS.FIRE_SETBACKS]: fireSetbackInitialData,
  [FORM_CONTROLS.OBSTRUCTION_SETBACKS]: obstructionSetbackInitialData,
  [FORM_CONTROLS.SIZED_FOR_CATEGORY]: 'production',
  [FORM_CONTROLS.SIZED_FOR_VALUE]: '',
  [FORM_CONTROLS.NEGATIVE_TOLERANCE]: -3,
  [FORM_CONTROLS.POSITIVE_TOLERANCE]: 10,
};

export const initialValuesForTrueUpWithNoDesign = {
  [FORM_CONTROLS.FIRE_SETBACKS]: fireSetbackInitialData,
  [FORM_CONTROLS.OBSTRUCTION_SETBACKS]: obstructionSetbackInitialData,
  [FORM_CONTROLS.SIZED_FOR_CATEGORY]: 'none',
  [FORM_CONTROLS.SIZED_FOR_VALUE]: '',
  [FORM_CONTROLS.NEGATIVE_TOLERANCE]: 0,
  [FORM_CONTROLS.POSITIVE_TOLERANCE]: 0,
};

export const initialValuesForSitePlan = {
  [FORM_CONTROLS.AHJ]: '',
  [FORM_CONTROLS.FIRE_SETBACKS]: fireSetbackInitialData,
  [FORM_CONTROLS.OBSTRUCTION_SETBACKS]: obstructionSetbackInitialData,
  [FORM_CONTROLS.SIZED_FOR_CATEGORY]: 'none',
  [FORM_CONTROLS.SIZED_FOR_VALUE]: '',
};

export const REQUIRED_ERROR = '*This field is required';
