import { useContext } from 'react';

import { ChecklistTemplateContext } from 'screens/ChecklistTemplate/Editor/ChecklistTemplateProvider';

import styled from 'styled-components';

import Section from 'screens/ChecklistTemplate/Editor/components/Section';

import colors from 'helpers/constants/colors';

// 655px is the minimum the current layout can support, we need to consider how
// the buttons should transition on smaller screens before we can go smaller
const ContentContainerDiv = styled.div`
  border-radius: 1.5rem;
  border: 1px solid ${colors.lightGray};
  background-color: ${colors.white};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  flex: 0.95
  overflow-y: auto;
  overflow-x: hidden;
`;

const Title = styled.h1`
  font-size: 2rem;
  text-overflow: ellipsis;
  flex: 0.01;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 2rem;
  flex: 0.8;
`;

type Props = {
  title: string;
};

export const ContentContainer = ({ title }: Props) => {
  const { activeSection } = useContext(ChecklistTemplateContext);
  if (!activeSection) {
    return null;
  }
  return (
    <Column>
      <Title>{title}</Title>
      <ContentContainerDiv>
        <Section key={activeSection.id} sectionData={activeSection} />
      </ContentContainerDiv>
    </Column>
  );
};
