import { Tooltip } from 'antd';
import { ReactComponent as QuestionMarkIcon } from 'assets/icons/question-mark-icon.svg';
import { FormikProps } from 'formik';
import breakpoints from 'helpers/constants/breakpoints';
import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';
import { TFunction } from 'i18next';
import { SIZED_FOR_CATEGORIES } from 'screens/DesignServices/constants';
import styled from 'styled-components';
import { FormInput } from '../components';
import { FORM_CONTROLS } from '../constants';
import { TRUE_UP_FORM } from './constants';

const TooltipWrapper = styled.div`
  margin-left: 1rem;
  display: flex;
  align-items: center;
`;

export const SizedForInputWrapper = styled.div`
  max-width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  section {
    margin: 0 0 0 0.5rem;
    .ant-input-group-addon {
      border-radius: 1rem 0 0 1rem;
      padding: 0 0.5rem;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    max-width: 60%;
  }

  #sizedForCategory {
    min-width: 16rem;
    margin-bottom: 0;
  }

  #negativeTolerance,
  #positiveTolerance {
    width: 6rem;
    margin-bottom: 0;
  }
`;

const SizedForTolerance = ({
  getFieldProps,
  setFieldValue,
  touched,
  errors,
  t,
}: { t: TFunction<'translation', undefined> } & Partial<FormikProps<Record<string, string>>>) => {
  if (!getFieldProps || !setFieldValue || !touched || !errors) {
    return <div></div>;
  }

  return (
    <SizedForInputWrapper>
      {/* Sized for category */}
      <FormInput
        title={t('SizedFor.title')}
        fieldProps={getFieldProps(FORM_CONTROLS.SIZED_FOR_CATEGORY)}
        id={FORM_CONTROLS.SIZED_FOR_CATEGORY}
        customSelect
        options={TRUE_UP_FORM.SIZED_FOR_CATEGORY.options}
        defaultValue={SIZED_FOR_CATEGORIES.production}
        onChange={(option: {
          key: string;
          value: keyof typeof TRUE_UP_FORM.SIZED_FOR_VALUE.placeholder;
        }) => {
          setFieldValue(FORM_CONTROLS.SIZED_FOR_CATEGORY, option.value);
        }}
        filter
        ariaLabel={t('SizedFor.categoryAriaLabel')}
      />

      {/* Tolerance value (-%) */}
      <FormInput
        fieldProps={getFieldProps(FORM_CONTROLS.NEGATIVE_TOLERANCE)}
        inputProps={{ addonBefore: '(-%)' }}
        title={t('SizedFor.tolerance')}
        id={FORM_CONTROLS.NEGATIVE_TOLERANCE}
        className={validateStatus(touched, errors, FORM_CONTROLS.NEGATIVE_TOLERANCE)}
        errorValidation={renderValidationMessage(touched, errors, FORM_CONTROLS.NEGATIVE_TOLERANCE)}
        type="number"
        step="1"
        min="0"
        onKeyDown={(e: KeyboardEvent) => {
          if (isNaN(Number(e.key)) && e.key !== 'Backspace' && e.key !== 'Tab') {
            e.preventDefault();
          }
        }}
        ariaLabel={t('SizedFor.negativeToleranceAriaLabel')}
      />
      {/* Tolerance value (+%) */}
      <FormInput
        fieldProps={getFieldProps(FORM_CONTROLS.POSITIVE_TOLERANCE)}
        inputProps={{ addonBefore: '(+%)' }}
        title=""
        id={FORM_CONTROLS.POSITIVE_TOLERANCE}
        className={validateStatus(touched, errors, FORM_CONTROLS.POSITIVE_TOLERANCE)}
        errorValidation={renderValidationMessage(touched, errors, FORM_CONTROLS.POSITIVE_TOLERANCE)}
        type="number"
        step="1"
        min="0"
        onKeyDown={(e: KeyboardEvent) => {
          if (isNaN(Number(e.key)) && e.key !== 'Backspace' && e.key !== 'Tab') {
            e.preventDefault();
          }
        }}
        ariaLabel={t('SizedFor.positiveToleranceAriaLabel')}
      />
      <TooltipWrapper>
        <Tooltip placement="bottomRight" title={t('tooltipTexts.tolerance')} arrowPointAtCenter>
          <QuestionMarkIcon />
        </Tooltip>
      </TooltipWrapper>
    </SizedForInputWrapper>
  );
};

export default SizedForTolerance;
