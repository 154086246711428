import { NavLink } from 'react-router-dom';

import { Tooltip } from 'antd';
import cn from 'classnames';
import { kebabCase } from 'lodash-es';

import { HTMLAttributes, ReactElement } from 'react';
import './MenuItem.scss';

type Props = {
  disabled?: boolean;
  isCollapsed?: boolean;
  title: string;
  children?: any;
  linkIsExternal?: boolean;
  isSelected?: boolean;
  openModalOrFollowLink?: (e: any) => void;
  className?: string;
  icon?: ReactElement;
  link?: string;
} & HTMLAttributes<HTMLDivElement>;

const MenuItem = ({
  icon,
  link,
  linkIsExternal,
  disabled = false,
  isSelected,
  className,
  isCollapsed,
  title,
  children,
  openModalOrFollowLink,
}: Props) => {
  const LinkComponent = linkIsExternal
    ? // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ({ children, to, exact, ...otherProps }: any) => (
        <a href={to} target="_blank" rel="noreferrer" {...otherProps}>
          {children}
        </a>
      )
    : !link
      ? // setting these to any for quickness sake but we should come back and adjust these
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ children, exact, ...otherProps }: any) => <div {...otherProps}>{children}</div>
      : NavLink;

  return (
    <div
      data-text="test"
      className={cn('Menu-Item-Wrapper', { 'Menu-Item-Wrapper--disabled': disabled }, className)}
    >
      <Tooltip title={title} overlayClassName={cn({ Hidden: !isCollapsed })} placement="right">
        <LinkComponent
          to={link}
          id={kebabCase(`Menu-Item-Link-${title}`)}
          className={cn(
            'Menu-Item',
            { 'Menu-Item--enabled': !disabled },
            { 'Menu-Item--disabled': disabled },
            { 'Menu-Item--expanded': !isCollapsed },
            { 'Menu-Item--collapsed': isCollapsed },
            { 'Menu-Item--selected': isSelected }
          )}
          onClick={openModalOrFollowLink}
          exact
        >
          {icon && (
            <div className={cn('Menu-Item-Icon', { 'Menu-Item-Icon--collapsed': isCollapsed })}>
              {icon}
            </div>
          )}
          <div
            className={cn(
              'Menu-Item-Child',
              { 'Menu-Item-Child--multi': title === 'Credits' },
              { 'Menu-Item-Child--enabled': !disabled },
              { 'Menu-Item-Child--disabled': disabled },
              { 'Menu-Item-Child--collapsed': isCollapsed }
            )}
            data-testid={`menu-item-child-${kebabCase(title)}`}
          >
            {children}
          </div>
        </LinkComponent>
      </Tooltip>
    </div>
  );
};

export default MenuItem;
