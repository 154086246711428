import { useContext } from 'react';

import { FieldTypeEnum, SectionType } from '@cpm/scanifly-shared-data';
import { Divider } from 'antd';
import { ChecklistTemplateContext } from 'screens/ChecklistTemplate/Editor/ChecklistTemplateProvider';

import styled from 'styled-components';

import colors from 'helpers/constants/colors';

import { FormField } from './FormField';

const SectionWrapper = styled.div`
  position: relative;
`;

const SectionHeader = styled.h2`
  text-overflow: ellipsis;
`;

const StyledDivider = styled(Divider)`
  padding: 0;
  margin-left: -4rem;
  width: 120%;
  color: ${colors.neutralGray};
`;

const ComponentsList = styled.ul`
  list-style-type: none;
  padding: 0 1rem;
`;

const ComponentWrapper = styled.li``;

type Props = {
  sectionData: SectionType;
};

function Section({ sectionData: { id, title, components } }: Props) {
  const { handleFieldDragStart, handleFieldDragOver, handleFieldDrop, editing } =
    useContext(ChecklistTemplateContext);

  return (
    <SectionWrapper>
      <SectionHeader>{title}</SectionHeader>
      <StyledDivider plain />
      <ComponentsList>
        {components.map((component, index) => (
          <ComponentWrapper
            key={component.id}
            draggable={
              component.componentType !== FieldTypeEnum.notApplicable &&
              !editing.includes(component.id)
            }
            onDragOver={handleFieldDragOver}
            onDrop={handleFieldDrop(index, id)}
            onDragStart={handleFieldDragStart(index)}
          >
            <FormField item={component} />
            <StyledDivider plain />
          </ComponentWrapper>
        ))}
      </ComponentsList>
    </SectionWrapper>
  );
}

export default Section;
