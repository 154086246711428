import { DESIGN_STATUS } from '@cpm/scanifly-shared-data';
import { ServiceRequest } from 'types';
import { FireSetbackData, ObstructionSetbackData } from 'types/DesignServiceSetting';

import API from 'api/config';

import {
  adjustCreditsUrl,
  chargeExtraCreditsForServiceRequestUrl,
  createDesignServiceRequestUrl,
  createDesignServicesSettingsUrl,
  deleteDesignServiceRequestUrl,
  fetchDesignServiceRequestsByProjectIdUrl,
  fetchDesignServicesQueueCompanyProjectsUrl,
  fetchDesignServicesQueueProjectsUrl,
  getDesignServicesSettingsUrl,
  getDesignServiceStatisticsUrl,
  listAllCreditTransactionsPerMonthUrl,
  listAllCreditTransactionsUrl,
  orderOneOffCreditsUrl,
  updateDesignServicesSettingsUrl,
  updateProjectDesignStatusUrl,
  updateServiceRequestUrl,
} from './designServicesUrls';

export const fetchProjectsForCSVExport = (companyId?: string) =>
  API.get(fetchDesignServicesQueueProjectsUrl(), {
    params: {
      companyId,
    },
  });

export const fetchProjectsForDesignServiceQueue = (
  pageIndex: number,
  size: number,
  sortBy?: {},
  filterBy?: {},
  companyId?: string
) => {
  return API.get(fetchDesignServicesQueueProjectsUrl(), {
    params: {
      pageIndex,
      size,
      sortBy,
      filterBy,
      companyId,
    },
  });
};

export const fetchCompanyProjectsForDesignServiceQueue = ({
  pageIndex,
  size,
  sortBy,
  filterBy,
  companyId,
}: {
  pageIndex: number;
  size: number;
  sortBy?: {};
  filterBy?: {};
  companyId?: string;
}) => {
  return API.get(fetchDesignServicesQueueCompanyProjectsUrl(companyId), {
    params: {
      pageIndex,
      size,
      sortBy,
      filterBy,
    },
  });
};

export const createDesignServiceRequest = (values: Partial<ServiceRequest>) => {
  return API.post(createDesignServiceRequestUrl(), values);
};

export const updateProjectDesignStatus = (designId: string, targetStatus?: DESIGN_STATUS) =>
  API.patch(updateProjectDesignStatusUrl(designId), { status: targetStatus });

export const deleteDesignServiceRequest = (designId: string) =>
  API.delete(deleteDesignServiceRequestUrl(designId));

export const fetchDesignServiceRequestsByProjectId = ({ projectId }: { projectId: string }) => {
  return API.get(fetchDesignServiceRequestsByProjectIdUrl(projectId));
};

export const orderALaCarteCredits = ({
  companyId,
  creditCount,
}: {
  companyId: string;
  creditCount: number;
}) => {
  return API.post(orderOneOffCreditsUrl(companyId), {
    creditCount,
  });
};

export const adjustCredits = ({
  companyId,
  quantity,
  category,
  memo,
}: {
  companyId: string;
  quantity: number;
  category: string;
  memo: string;
}) => {
  return API.post(adjustCreditsUrl(companyId), {
    memo,
    quantity,
    category,
    paymentAmountUsd: 0,
  });
};

export const fetchAllCreditTransactionsForCompany = (
  companyId: string,
  pageIndex: number,
  size?: {},
  sortBy?: {},
  filterBy?: {}
) => {
  return API.get(listAllCreditTransactionsUrl(companyId), {
    params: {
      pageIndex,
      size,
      sortBy,
      filterBy,
    },
  });
};

export const fetchAllCreditTransactionsForMonth = ({
  month,
  year,
  pageIndex,
  size,
  sortBy,
  filterBy,
}: {
  month: number;
  year: number;
  pageIndex: number;
  size: number;
  sortBy?: {};
  filterBy?: {};
}) => {
  return API.get(listAllCreditTransactionsPerMonthUrl(month, year), {
    params: {
      pageIndex,
      size,
      sortBy,
      filterBy,
    },
  });
};

export const updateServiceRequest = ({
  serviceRequestId,
  finalData,
  status,
}: {
  serviceRequestId: string;
  finalData: ServiceRequest;
  status?: string;
}) => {
  return API.patch<ServiceRequest>(updateServiceRequestUrl(serviceRequestId), {
    ...finalData,
    status,
  });
};

export const chargeExtraCreditsForServiceRequest = (
  creditCount: number,
  serviceRequestId: string,
  companyId: string,
  memo: string
) => {
  return API.post(chargeExtraCreditsForServiceRequestUrl(), {
    creditCount,
    serviceRequestId,
    companyId,
    memo,
  });
};

export const fetchDesignServiceStatistics = () => {
  return API.get(getDesignServiceStatisticsUrl());
};

export const fetchDesignServiceSettings = (companyId: string) => {
  return API.get(getDesignServicesSettingsUrl(companyId));
};

export const createDesignServiceSettings = ({
  companyId,
  folderId,
  obstructionSetbacks,
  fireSetbacks,
  attachment,
  racking,
  moduleInfo,
  inverterInfo,
}: {
  companyId: string;
  folderId: string;
  obstructionSetbacks: ObstructionSetbackData;
  fireSetbacks: FireSetbackData;
  attachment?: string;
  racking?: string;
  moduleInfo?: string;
  inverterInfo?: string;
}) => {
  return API.post(createDesignServicesSettingsUrl(companyId), {
    folderId,
    obstructionSetbacks,
    fireSetbacks,
    attachment,
    racking,
    moduleInfo,
    inverterInfo,
  });
};

export const updateDesignServiceSettings = ({
  companyId,
  folderId,
  obstructionSetbacks,
  fireSetbacks,
  attachment,
  racking,
  moduleInfo,
  inverterInfo,
}: {
  companyId: string;
  folderId: string;
  obstructionSetbacks: ObstructionSetbackData;
  fireSetbacks: FireSetbackData;
  attachment?: string;
  racking?: string;
  moduleInfo?: string;
  inverterInfo?: string;
}) => {
  return API.patch(updateDesignServicesSettingsUrl(companyId), {
    folderId,
    obstructionSetbacks,
    fireSetbacks,
    attachment,
    racking,
    moduleInfo,
    inverterInfo,
  });
};
