import {
  DESIGN_SERVICE_CREDITS,
  FEATURE_LIST,
  GENERAL_SERVICE_CREDITS,
  TIER_LEVEL_DESCRIPTIONS,
} from '@cpm/scanifly-shared-data';

import { i18n } from 'helpers/utils/translations';

const siteModelingData = (designServicesAccess: boolean, isExpedited: boolean = false) => {
  if (designServicesAccess) {
    return [
      {
        title: `${TIER_LEVEL_DESCRIPTIONS.siteModeling} ${isExpedited ? i18n.t('DesignService.expedited') : ''}`,
        creditCount:
          DESIGN_SERVICE_CREDITS.siteModeling +
          (isExpedited ? GENERAL_SERVICE_CREDITS[FEATURE_LIST.DESIGN_SERVICE_EXPEDITED_PRELIM] : 0),
      },
    ];
  }
  return [];
};

const maxFillData = (designServicesAccess: boolean, isExpedited: boolean = false) => {
  if (designServicesAccess) {
    return [
      {
        title: `${TIER_LEVEL_DESCRIPTIONS.maxFill} ${isExpedited ? i18n.t('DesignService.expedited') : ''}`,
        creditCount:
          DESIGN_SERVICE_CREDITS.maxFill +
          (isExpedited ? GENERAL_SERVICE_CREDITS[FEATURE_LIST.DESIGN_SERVICE_EXPEDITED_PRELIM] : 0),
      },
    ];
  }
  return [];
};

export const DesignServicesCreditList = (designServicesAccess: boolean) => {
  return [
    [
      {
        title: TIER_LEVEL_DESCRIPTIONS.wireframe,
        creditCount: DESIGN_SERVICE_CREDITS.wireframe,
      },
      ...siteModelingData(designServicesAccess),
    ],
    [
      {
        title: TIER_LEVEL_DESCRIPTIONS.trueUp,
        creditCount: DESIGN_SERVICE_CREDITS.trueUp,
      },
      ...siteModelingData(designServicesAccess, true),
    ],
    [
      {
        title: TIER_LEVEL_DESCRIPTIONS.sitePlan,
        creditCount: DESIGN_SERVICE_CREDITS.sitePlan,
      },
      ...maxFillData(designServicesAccess),
    ],
    [
      {
        title: TIER_LEVEL_DESCRIPTIONS.planSet,
        creditCount: DESIGN_SERVICE_CREDITS.planSet,
      },
      ...maxFillData(designServicesAccess, true),
    ],
    [
      {
        title: i18n.t('DesignService.designApproved.asBuilt'),
        creditCount: i18n.t('DesignService.comingSoon'),
      },
    ],
  ];
};

export const NearmapCreditList = [
  [
    {
      title: i18n.t('Credits.1000m2'),
      creditCount: GENERAL_SERVICE_CREDITS.nearmaps.toString(),
    },
    {
      title: i18n.t('Credits.additional1000m2'),
      creditCount: `+${GENERAL_SERVICE_CREDITS.nearmaps}`,
    },
  ],
  [
    {
      title: i18n.t('Credits.allAvailableDates'),
      creditCount: i18n.t('Credits.creditCountForAllAvailableDates'),
    },
  ],
];
