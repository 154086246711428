import { MouseEventHandler, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button } from 'antd';
import cn from 'classnames';
import { FieldConfig, FieldInputProps, FormikErrors, FormikTouched, FormikValues } from 'formik';

import { dspCompaniesRequested } from 'state/slices/companySlice';
import { AppDispatch } from 'state/store/store';

import { ReactComponent as TickIcon } from 'assets/icons/tick-icon.svg';

import AccountingDesignServicesForm from './AccountingDesignServicesForm';
import AccountingNotesForm from './AccountingNotesForm';
import AccountingProjectInfoForm from './AccountingProjectInfoForm';
import AccountingScaniflyInfoForm from './AccountingScaniflyInfoForm';
import AccountingSubscriptionInfoForm from './AccountingSubscriptionInfoForm';
import AvailableFeaturesForm from './AvailableFeatures';

const AccountingForm = ({
  touched,
  errors,
  getFieldProps,
  setFieldValue,
  informationSaved,
  handleSubmit,
  setSelectedSubscriptionId,
  selectedSubscriptionId,
  setInformationSaved,
}: {
  touched: FormikTouched<FormikValues>;
  errors: FormikErrors<FormikValues>;
  getFieldProps: (nameOrOptions: string | FieldConfig<any>) => FieldInputProps<any>;
  setFieldValue: (
    field: string,
    value: string | number,
    shouldValidate?: boolean | undefined
  ) => void;
  handleSubmit: MouseEventHandler<HTMLElement>;
  setInformationSaved: () => void;
  setSelectedSubscriptionId: () => void;
  informationSaved: boolean;
  selectedSubscriptionId: string | undefined;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(dspCompaniesRequested());
  }, [dispatch]);

  return (
    <>
      <AccountingSubscriptionInfoForm
        getFieldProps={getFieldProps}
        setFieldValue={setFieldValue}
        selectedSubscriptionId={selectedSubscriptionId}
        setSelectedSubscriptionId={setSelectedSubscriptionId}
        setInformationSaved={setInformationSaved}
        errors={errors}
        touched={touched}
      />
      <AvailableFeaturesForm getFieldProps={getFieldProps} />
      <AccountingDesignServicesForm getFieldProps={getFieldProps} setFieldValue={setFieldValue} />
      <AccountingProjectInfoForm
        getFieldProps={getFieldProps}
        selectedSubscriptionId={selectedSubscriptionId}
      />
      <AccountingScaniflyInfoForm
        getFieldProps={getFieldProps}
        setFieldValue={setFieldValue}
        errors={errors}
        touched={touched}
      />
      <AccountingNotesForm getFieldProps={getFieldProps} />
      <div className="Accounting-Button-Wrapper">
        <TickIcon
          className={cn({
            Hidden: !informationSaved,
          })}
        />
        <Button className="Button--Blue" onClick={handleSubmit}>
          {selectedSubscriptionId === '' || selectedSubscriptionId === undefined
            ? t('AccountingForms.create')
            : t('AccountingForms.update')}
        </Button>
      </div>
    </>
  );
};

export default AccountingForm;
