import { DESIGN_STATUS, TIER_LEVEL } from '@cpm/scanifly-shared-data';

import { DESIGN_ACTIONS } from '../constants';

const {
  completed: completedAction,
  dataRequest: dataRequestAction,
  designing: designingAction,
  dataRefinement: dataRefinementAction,
  requestApproval: requestApprovalAction,
} = DESIGN_ACTIONS;
const {
  ordered,
  dataReview,
  dataRequest,
  designInProgress,
  dataRefinement,
  revisionInProgress,
  completed,
} = DESIGN_STATUS;
const {
  wireframe,
  wireframeForLarge,
  sitePlan,
  planSet,
  planSetForLarge,
  maxFill,
  siteModeling,
  trueUp,
} = TIER_LEVEL;

export const getActionList = ({
  tierLevel,
  status,
}: {
  tierLevel: TIER_LEVEL;
  status: DESIGN_STATUS;
}) => {
  if (
    (tierLevel === wireframe ||
      tierLevel === wireframeForLarge ||
      tierLevel === maxFill ||
      tierLevel === siteModeling) &&
    status !== completed
  ) {
    if (status === ordered) {
      return [designingAction, completedAction];
    }
    return [completedAction];
  } else if (
    tierLevel === sitePlan ||
    tierLevel === planSet ||
    tierLevel === planSetForLarge ||
    tierLevel === trueUp
  ) {
    if (status === dataReview) {
      return [dataRequestAction, designingAction];
    } else if (status === ordered) {
      return [designingAction];
    } else if (status === dataRequest) {
      return [designingAction];
    } else if (status === designInProgress) {
      if (tierLevel === trueUp) {
        return [dataRefinementAction, completedAction, requestApprovalAction];
      }
      return [dataRefinementAction, completedAction];
    } else if (status === dataRefinement) {
      return [designingAction];
    } else if (status === revisionInProgress) {
      return [completedAction];
    }
  }
  return [];
};
