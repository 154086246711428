import { DESIGN_STATUS, MODE, TIER_LEVEL } from '@cpm/scanifly-shared-data';
import { Revision } from 'types';
import ServiceRequest from 'types/ServiceRequest';

import { i18n } from 'helpers/utils/translations';

import { errorStatuses, MODALS, processingStatuses } from '../constants';
import { StepStatus } from '../OrderDesign/DesignStatus/DesignStatus';
import DataRefinement from '../OrderDesign/DesignStatus/StatusContent/DataRefinement';
import DataReview from '../OrderDesign/DesignStatus/StatusContent/DataReview';
import DesignCompleted from '../OrderDesign/DesignStatus/StatusContent/DesignCompleted';
import DesignInProgress from '../OrderDesign/DesignStatus/StatusContent/DesignInProgress';
import DesignPendingApproval from '../OrderDesign/DesignStatus/StatusContent/DesignPendingApproval';
import ProjectProcessing from '../OrderDesign/DesignStatus/StatusContent/ProjectProcessing';
import ProjectProcessingError from '../OrderDesign/DesignStatus/StatusContent/ProjectProcessingError';
import RevisionInProgress from '../OrderDesign/DesignStatus/StatusContent/RevisionInProgress';

type Props = {
  hasSitePlanOrPlanSet?: number;
  incompleteServiceRequest?: ServiceRequest | null;
  handleModalOpen?: (modal?: string) => void;
  serviceRequestToBeRevised?: ServiceRequest;
  handleOpenRevisionDataModal?: (revision: Revision) => void;
};

export const getContentToRender = ({
  hasSitePlanOrPlanSet = 0,
  incompleteServiceRequest,
  handleModalOpen = () => {},
  serviceRequestToBeRevised,
  handleOpenRevisionDataModal = () => {},
}: Props) => {
  const { project, status, tierLevel, isExpedited } = incompleteServiceRequest || {};
  const { id: projectId, status: projectStatus } = project || {};
  const mode = project?.mode ?? MODE.normal;

  if (
    !projectStatus ||
    !status ||
    !tierLevel ||
    (!incompleteServiceRequest && hasSitePlanOrPlanSet)
  ) {
    return {
      content: (
        <DesignCompleted
          hasSitePlanOrPlanSet={hasSitePlanOrPlanSet}
          handleModalOpen={() => handleModalOpen(MODALS.REVISION)}
          serviceRequestToBeRevised={serviceRequestToBeRevised}
          handleOpenRevisionDataModal={handleOpenRevisionDataModal}
        />
      ),
      status: StepStatus.finish,
    };
  } else if (processingStatuses.includes(projectStatus) && status !== DESIGN_STATUS.canceled) {
    return {
      content: (
        <ProjectProcessing
          handleModalOpen={handleModalOpen}
          mode={mode}
          projectStatus={projectStatus}
        />
      ),
      status: StepStatus.wait,
    };
  } else if (errorStatuses.includes(projectStatus)) {
    return {
      content: <ProjectProcessingError handleModalOpen={handleModalOpen} />,
      status: StepStatus.wait,
    };
  } else if (status === DESIGN_STATUS.pendingApproval && incompleteServiceRequest) {
    return {
      content: <DesignPendingApproval incompleteServiceRequest={incompleteServiceRequest} />,
      status: StepStatus.wait,
    };
  } else if (status === DESIGN_STATUS.designInProgress || status === DESIGN_STATUS.ordered) {
    return {
      content: (
        <DesignInProgress
          handleModalOpen={handleModalOpen}
          tierLevel={tierLevel}
          isExpedited={isExpedited}
        />
      ),
      status: StepStatus.wait,
    };
  } else if (status === DESIGN_STATUS.dataReview) {
    return {
      content: (
        <DataReview
          handleModalOpen={handleModalOpen}
          status={status}
          projectStatus={projectStatus}
        />
      ),
      status: StepStatus.wait,
    };
  } else if (status === DESIGN_STATUS.dataRequest || status === DESIGN_STATUS.dataRefinement) {
    return {
      content: (
        <DataRefinement handleModalOpen={handleModalOpen} status={status} projectId={projectId} />
      ),
      status: StepStatus.wait,
    };
  } else if (status === DESIGN_STATUS.revisionInProgress) {
    return {
      content: <RevisionInProgress tierLevel={tierLevel} />,
      status: StepStatus.wait,
    };
  }
  return {
    content: <DesignCompleted />,
    status: StepStatus.finish,
  };
};

export const getAllDesignStatusSteps = (
  tierLevel = TIER_LEVEL.wireframe,
  status = DESIGN_STATUS.completed
) => {
  const isWireframeOrMaxFillOrSiteModeling =
    tierLevel === TIER_LEVEL.wireframe ||
    tierLevel === TIER_LEVEL.wireframeForLarge ||
    tierLevel === TIER_LEVEL.maxFill ||
    tierLevel === TIER_LEVEL.siteModeling;

  if (isWireframeOrMaxFillOrSiteModeling) {
    if (status === DESIGN_STATUS.revisionInProgress) {
      return getDesignStatusStepsForWireframesWithRevision;
    }
    return getAllDesignStatusStepsForWireframe;
  } else {
    if (status === DESIGN_STATUS.revisionInProgress) {
      return getAllUpdatedDesignStatusStepsForSitePlanAndPlanSetsWithRevision;
    }
  }
  return getAllDesignStatusStepsForSitePlanAndPlanSets;
};

export const getAllDesignStatusStepsForWireframe = (content: JSX.Element) => {
  return [
    {
      stepNumber: 1,
      description: i18n.t('DesignService.designing'),
      content: content,
    },
    {
      stepNumber: 2,
      description: i18n.t('DesignService.completed'),
      content: content,
    },
  ];
};

export const getAllDesignStatusStepsForSitePlanAndPlanSets = (content: JSX.Element) => {
  return [
    {
      stepNumber: 1,
      description: i18n.t('DesignService.dataReview'),
      content: content,
    },
    {
      stepNumber: 2,
      description: i18n.t('DesignService.dataRequest'),
      content: content,
    },
    {
      stepNumber: 3,
      description: i18n.t('DesignService.designInProgressStatus'),
      content: content,
    },
    {
      stepNumber: 4,
      description: i18n.t('DesignService.dataRefinement'),
      content: content,
    },
    {
      stepNumber: 5,
      description: i18n.t('DesignService.designReview'),
      content: content,
    },
  ];
};

export const getAllUpdatedDesignStatusStepsForSitePlanAndPlanSetsWithRevision = (
  content: JSX.Element
) => {
  return [
    ...getAllDesignStatusStepsForSitePlanAndPlanSets(content),
    {
      stepNumber: 6,
      description: i18n.t('DesignService.revisionInProgress'),
      content: content,
    },
  ];
};

export const getDesignStatusStepsForWireframesWithRevision = (content: JSX.Element) => {
  return [
    ...getAllDesignStatusStepsForWireframe(content),
    {
      stepNumber: 3,
      description: i18n.t('DesignService.revisionInProgress'),
      content: content,
    },
  ];
};
