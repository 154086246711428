import useOnclickOutside from 'react-cool-onclickoutside';

import PropTypes from 'prop-types';

import useAuth from 'helpers/hooks/useAuth';
import { i18n } from 'helpers/utils/translations';

import { ReactComponent as LogoutIcon } from 'assets/icons/logout-icon.svg';

import FeatureFlags from './FeatureFlags';

const HeaderMenu = ({ toggleMenu }) => {
  const menuRef = useOnclickOutside(() => {
    toggleMenu();
  });
  const { logout } = useAuth();

  return (
    <div ref={menuRef} className="Header-MenuWrapper">
      <ul className="Header-Menu">
        <li>
          <button className="Header-Button Header-MenuItem" onClick={logout}>
            <LogoutIcon />
            {i18n.t('Header.logout')}
          </button>
        </li>
      </ul>
      <FeatureFlags />
    </div>
  );
};

HeaderMenu.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
};

export default HeaderMenu;
