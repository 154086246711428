import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  DESIGN_TIME_ESTIMATES,
  estimateTurnaroundTimeForSmall,
  TIER_LEVEL,
} from '@cpm/scanifly-shared-data';

import { handleMyProjectsButtonClick } from 'screens/DesignServices/helpers/handleMyProjectsButtonClick';

import { ReactComponent as InProgressIcon } from 'assets/icons/in-progress-icon.svg';

import ActionButtons from '../ActionButtons/ActionButtons';

const DesignInProgress = ({
  handleModalOpen,
  tierLevel,
  isExpedited = false,
}: {
  handleModalOpen: () => void;
  tierLevel: TIER_LEVEL;
  isExpedited?: boolean;
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const processingTimeEstimate = isExpedited
    ? `${estimateTurnaroundTimeForSmall({ tierLevel, isExpedited }).design} ${t('DesignService.minutes')}`
    : `~${DESIGN_TIME_ESTIMATES[tierLevel as keyof typeof DESIGN_TIME_ESTIMATES] ?? 3} ${t('DesignService.hours')}`;

  return (
    <div className="DesignStatus-Wrapper">
      <InProgressIcon className="DesignStatus-ProcessingIcon" />
      <div className="DesignStatus-Title">{t('DesignService.designInProgress.context')}</div>
      <p className="DesignStatus-Timeframe">
        {t('DesignService.designInProgress.timeframe')}{' '}
        <span className="DesignStatus-Value">{processingTimeEstimate}</span>
      </p>
      <ActionButtons
        handleClick={() => handleMyProjectsButtonClick(history)}
        handleCancel={handleModalOpen}
        hideCancelButton={true}
      />
    </div>
  );
};

export default DesignInProgress;
