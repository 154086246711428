import { UploadResult } from '@uppy/core';
import { Button, ModalContext, UploadDashboardModal, UploadThumbnail } from 'components';
import colors from 'helpers/constants/colors';
import usePermissions from 'helpers/hooks/usePermissions';
import useToggle from 'helpers/hooks/useToggle';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useSelector } from 'react-redux';
import { selectCompanyId } from 'state/slices/companySlice/selectors';
import { deleteExampleMedia } from 'state/slices/exampleMedia';
import { createExampleMediaAction } from 'state/slices/exampleMedia/exampleMediaSlice';
import styled from 'styled-components';
import ModalMedia from 'types/ModalMedia';
import { ChecklistTemplateContext } from '../ChecklistTemplateProvider';

type Props = {
  id: string;
  onChange: (id: string[]) => void;
  value: string[];
};
const MediaContainer = styled.div`
  border-radius: 10px;
  border: 1px solid ${colors.neutralGray};
  background-color: ${colors.background};
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;

  span {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  img {
    margin-bottom: 1rem;
  }
`;

const Container = styled.div`
  .row {
    display: grid !important;
    grid-template-columns: 2fr 1fr;
  }
`;
const MediaThumbnail = styled(UploadThumbnail)`
  height: 10rem;
  width: 10rem;
  border: solid;
  border-color: ${colors.midGray};
`;

const MediaAnswerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const PlaceHolderThumbnail = styled.div`
  height: 10rem;
  width: 10rem;
  border: solid;
  border-color: ${colors.midGray};
  background-color: ${colors.darkGrayOpacity};
`;

export const UploadButton = ({ onChange, value, id }: Props) => {
  const { isQa } = usePermissions();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const companyId = useSelector(selectCompanyId);

  const { templateId, activeSection, exampleMediaMap, getExampleMediaAction } =
    useContext(ChecklistTemplateContext);
  const { displayMediaViewModal } = useContext(ModalContext);

  const [isUploadModalVisible, toggleIsUploadModalVisible] = useToggle(false);
  const fieldMedia = exampleMediaMap.filter((item) => item.fieldId === id);

  const deleteExampleMediaAction = useCallback(
    async ({ mediaId }: { mediaId: string }) => {
      const response = await dispatch<any>(deleteExampleMedia({ mediaId, templateId }));

      if (response.status === 200) {
        const filteredMedia = value.filter((mediaItem) => mediaItem !== mediaId);
        onChange(filteredMedia);
        getExampleMediaAction(templateId);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, value, onChange, templateId]
  );

  const onUploadComplete = useCallback(
    (_: UploadResult) => {
      getExampleMediaAction(templateId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createExampleMediaAction]
  );

  useEffect(() => {
    const updatedValue = fieldMedia.map((item) => {
      return item.id;
    });
    onChange(updatedValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldMedia.length]);

  const onThumbnailClick = useCallback(
    (mediaId: string) => {
      if (fieldMedia) {
        const mediaMap: { [key: string]: ModalMedia } = {};
        for (const mediaItem of fieldMedia) {
          const refId = mediaItem.id;
          mediaMap[refId] = {
            thumbnailUrl: mediaItem.thumbnailUrl,
            fullSizeUrl: mediaItem.imgUrl,
            name: `${mediaItem.originalFilenameWithoutExtension}${mediaItem.originalFileExtension}`,
          };
        }
        displayMediaViewModal({
          mediaMap: mediaMap,
          initialSelectedId: mediaId,
          mediaOrder: value,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fieldMedia, value]
  );

  const mediaDisplay = useMemo(() => {
    const media = fieldMedia.map((media) => {
      const thumbnailUrl = media.thumbnailUrl;
      const imgUrl = media.imgUrl;
      const mediaId = media.id;

      if (thumbnailUrl)
        return (
          <MediaThumbnail
            key={`${mediaId}-wrapper`}
            handleDelete={() => deleteExampleMediaAction({ mediaId })}
            onClick={() => onThumbnailClick(mediaId)}
            src={thumbnailUrl}
            isVideo={false}
          />
        );
      if (imgUrl)
        return (
          <MediaThumbnail
            key={`${mediaId}-wrapper`}
            onClick={() => onThumbnailClick(mediaId)}
            handleDelete={() => deleteExampleMediaAction({ mediaId })}
            src={imgUrl}
            isVideo={false}
          />
        );
      return <PlaceHolderThumbnail key={mediaId} />;
    });

    return media;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldMedia]);

  if (!isQa) return null;

  return (
    <>
      <Container>
        <UploadDashboardModal
          isModalVisible={isUploadModalVisible}
          toggleIsModalVisible={toggleIsUploadModalVisible}
          handleUploadComplete={onUploadComplete}
          sectionId={activeSection!.id}
          fieldId={id}
          categoryId={templateId}
          endpoint={`${process.env.REACT_APP_API}/exampleMedia/upload-xhr?companyId=${companyId}`}
        />
        <MediaAnswerWrapper>
          <Button
            color={colors.mainBlue}
            label={t('Checklists.exampleImages')}
            onClick={toggleIsUploadModalVisible}
            icon="upload"
            width="25rem"
            iconSize="2rem"
          />
          {value.length ? <MediaContainer>{mediaDisplay}</MediaContainer> : null}
        </MediaAnswerWrapper>
      </Container>
    </>
  );
};
