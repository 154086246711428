import { ButtonLink } from 'components';
import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';
import fontWeights from 'helpers/constants/fontWeights';
import styled from 'styled-components';

const SectionHeader = styled.h2`
  font-size: ${fontSizes.medium};
  color: ${colors.darkGray};
  font-weight: ${fontWeights.semiBold};
  margin-bottom: 2rem;
`;

const CreditsHeader = styled.h2`
  font-size: ${fontSizes.medium};
  color: ${colors.darkGray};
  font-weight: ${fontWeights.semiBold};
  margin-bottom: 0 !important;
`;

const SubHeader = styled.h2`
  font-size: ${fontSizes.mediumSmall};
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  color: ${colors.darkGray};
`;

const CreditsDescriptionText = styled.p`
  padding-top: 4rem;
  color: ${colors.labelGray};
  font-size: ${fontSizes.extraSmall};
`;

const SectionHeaderWithNote = styled.h2`
  font-size: ${fontSizes.medium};
  color: ${colors.darkGray};
  font-weight: ${fontWeights.semiBold};
  margin-bottom: 0;
  display: flex;
  align-items: center;
`;

const Note = styled.div`
  max-width: 30rem;
  color: ${colors.labelGray};
  font-size: ${fontSizes.extraSmall};
  margin-bottom: 0;
  display: flex;
  align-items: center;
`;

const NoteWithLink = styled.div`
  max-width: 30rem;
  color: ${colors.labelGray};
  font-size: ${fontSizes.extraSmall};
  margin-bottom: 0;
`;

const Value = styled.span`
  margin-left: 0.5rem;
`;

const CreditHistoryLink = styled(ButtonLink)`
  margin-top: -2rem;
`;

export {
  CreditHistoryLink,
  CreditsDescriptionText,
  CreditsHeader,
  Note,
  NoteWithLink,
  SectionHeader,
  SectionHeaderWithNote,
  SubHeader,
  Value,
};
