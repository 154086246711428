import { createContext, useCallback, useMemo, useState } from 'react';

import ConditionalModal from './ConditionalModal/ConditionalModal';
import ConfirmationModal from './ConfirmationModal/ConfirmationModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import FormModal from './FormModal';
import ImportModal from './ImportModal';
import MediaViewModal from './MediaViewModal';
import TextInputModal from './TextInputModal';
import {
  ConditionalModalProps,
  ConfirmationModalProps,
  DeleteModalProps,
  FormModalProps,
  ImportModalProps,
  InputModalProps,
  MediaViewModalProps,
  ModalContextType,
  ModalProviderProps,
} from './types';

export const ModalContext = createContext<ModalContextType>({
  displayDeleteModal: () => {},
  displayInputModal: () => {},
  displayImportModal: () => {},
  displayConditionalModal: () => {},
  displayFormModal: () => {},
  displayConfirmationModal: () => {},
  displayMediaViewModal: () => {},
});

const ModalProvider = ({ children }: ModalProviderProps) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [deleteModalProps, setDeleteModalProps] = useState<DeleteModalProps>({
    title: 'Error',
    description: 'This modal was not properly initialized...',
    actionButtonOnClick: () => {},
    actionButtonLabel: 'Cancel',
    onCancel: () => {},
  });

  const [inputModalVisible, setInputModalVisible] = useState<boolean>(false);
  const [inputModalProps, setInputModalProps] = useState<InputModalProps>({
    title: 'Error',
    description: 'This modal was not properly initialized...',
    placeholderText: 'na',
    actionButtonOnClick: (_) => {},
    actionButtonLabel: 'Cancel',
    onCancel: () => {},
  });

  const [conditionalModalVisible, setConditionalModalVisible] = useState<boolean>(false);
  const [conditionalModalProps, setConditionalModalProps] = useState<ConditionalModalProps>({
    title: 'Error',
    description: 'This modal was not properly initialized...',
    actionButtonOnClick: (_) => {},
    actionButtonLabel: 'Cancel',
    onCancel: () => {},
  });

  const [formModalVisible, setFormModalVisible] = useState<boolean>(false);
  const [formModalProps, setFormModalProps] = useState<FormModalProps>({
    title: 'Error',
    description: 'This modal was not properly initialized...',
    formFields: <></>,
    actionButtonOnClick: () => {},
    actionButtonLabel: 'Cancel',
    onCancel: () => {},
  });

  const [confirmationModalVisible, setConfirmationModalVisible] = useState<boolean>(false);
  const [confirmationModalProps, setConfirmationModalProps] = useState<ConfirmationModalProps>({
    title: 'Error',
    description: 'This modal was not properly initialized...',
    actionButtonOnClick: () => {},
    actionButtonLabel: 'Cancel',
    onCancel: () => {},
  });

  const displayDeleteModal = useCallback((props: DeleteModalProps) => {
    setDeleteModalProps(props);
    setDeleteModalVisible(true);
  }, []);
  const displayInputModal = useCallback((props: InputModalProps) => {
    setInputModalProps(props);
    setInputModalVisible(true);
  }, []);
  const displayConditionalModal = useCallback((props: ConditionalModalProps) => {
    setConditionalModalProps(props);
    setConditionalModalVisible(true);
  }, []);
  const displayFormModal = useCallback((props: FormModalProps) => {
    setFormModalProps(props);
    setFormModalVisible(true);
  }, []);
  const displayConfirmationModal = useCallback((props: ConfirmationModalProps) => {
    setConfirmationModalProps(props);
    setConfirmationModalVisible(true);
  }, []);

  const [mediaViewModalProps, setMediaViewModalProps] = useState<MediaViewModalProps>({
    mediaMap: {},
    mediaOrder: [],
  });
  const [mediaViewModalVisible, setMediaViewModalVisible] = useState(false);
  const displayMediaViewModal = useCallback((props: MediaViewModalProps) => {
    setMediaViewModalProps(props);
    setMediaViewModalVisible(true);
  }, []);

  const [importModalVisible, setImportModalVisible] = useState<boolean>(false);
  const [importModalProps, setImportModalProps] = useState<ImportModalProps>({
    title: 'Error',
    description: 'This modal was not properly initialized...',
    actionButtonLabel: 'error',
    actionButtonOnClick: (_) => {},
    onCancel: () => {},
  });
  const displayImportModal = useCallback((props: ImportModalProps) => {
    setImportModalProps(props);
    setImportModalVisible(true);
  }, []);

  const value = useMemo(
    () => ({
      displayDeleteModal,
      displayInputModal,
      displayImportModal,
      displayConditionalModal,
      displayFormModal,
      displayConfirmationModal,
      displayMediaViewModal,
    }),
    [
      displayDeleteModal,
      displayImportModal,
      displayInputModal,
      displayConditionalModal,
      displayFormModal,
      displayConfirmationModal,
      displayMediaViewModal,
    ]
  );

  return (
    <ModalContext.Provider value={value}>
      {children}
      <TextInputModal
        title={inputModalProps.title}
        subtitle={inputModalProps.subtitle}
        description={inputModalProps.description}
        instruction={inputModalProps.instruction}
        actionButtonOnClick={inputModalProps.actionButtonOnClick}
        actionButtonColor={inputModalProps.actionButtonColor}
        confirmValue={inputModalProps.confirmValue}
        defaultValue={inputModalProps.defaultValue}
        actionButtonLabel={inputModalProps.actionButtonLabel}
        isVisible={inputModalVisible}
        setIsVisible={setInputModalVisible}
        maxLength={inputModalProps.maxLength}
      />
      <DeleteConfirmationModal
        title={deleteModalProps.title}
        description={deleteModalProps.description}
        actionButtonOnClick={deleteModalProps.actionButtonOnClick}
        actionButtonLabel={deleteModalProps.actionButtonLabel}
        onCancel={deleteModalProps.onCancel}
        isVisible={deleteModalVisible}
        setIsVisible={setDeleteModalVisible}
      />
      <ConditionalModal
        title={conditionalModalProps.title}
        description={conditionalModalProps.description}
        actionButtonOnClick={conditionalModalProps.actionButtonOnClick}
        actionButtonLabel={conditionalModalProps.actionButtonLabel}
        onCancel={conditionalModalProps.onCancel}
        isVisible={conditionalModalVisible}
        setIsVisible={setConditionalModalVisible}
      />
      <FormModal
        title={formModalProps.title}
        description={formModalProps.description}
        formFields={formModalProps.formFields}
        actionButtonOnClick={formModalProps.actionButtonOnClick}
        actionButtonLabel={formModalProps.actionButtonLabel}
        actionButtonTestId={formModalProps.actionButtonTestId}
        onCancel={formModalProps.onCancel}
        isVisible={formModalVisible}
        setIsVisible={setFormModalVisible}
      />
      <ConfirmationModal
        title={confirmationModalProps.title}
        description={confirmationModalProps.description}
        actionButtonOnClick={confirmationModalProps.actionButtonOnClick}
        actionButtonLabel={confirmationModalProps.actionButtonLabel}
        actionButtonTestId={confirmationModalProps.actionButtonTestId}
        onCancel={confirmationModalProps.onCancel}
        isVisible={confirmationModalVisible}
        setIsVisible={setConfirmationModalVisible}
      />
      <MediaViewModal
        mediaMap={mediaViewModalProps.mediaMap}
        mediaOrder={mediaViewModalProps.mediaOrder}
        initialSelectedId={mediaViewModalProps.initialSelectedId}
        isVisible={mediaViewModalVisible}
        setIsVisible={setMediaViewModalVisible}
      />
      <ImportModal
        title={importModalProps.title}
        description={importModalProps.description}
        actionButtonLabel={importModalProps.actionButtonLabel}
        actionButtonOnClick={importModalProps.actionButtonOnClick}
        onCancel={importModalProps.onCancel}
        isVisible={importModalVisible}
        setIsVisible={setImportModalVisible}
      />
    </ModalContext.Provider>
  );
};

export default ModalProvider;
