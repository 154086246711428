import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { DESIGN_STATUS, STATUS_ESTIMATES } from '@cpm/scanifly-shared-data';

import {
  ADMIN_PROJECT_STATUSES,
  NON_ADMIN_PROJECT_STATUSES,
} from 'helpers/constants/projectStatuses';
import { handleMyProjectsButtonClick } from 'screens/DesignServices/helpers/handleMyProjectsButtonClick';

import { ReactComponent as DataReviewIcon } from 'assets/data-review-icon.svg';

import ActionButtons from '../ActionButtons/ActionButtons';

const DataReview = ({
  handleModalOpen,
  status,
  projectStatus,
}: {
  handleModalOpen: () => void;
  status: string;
  projectStatus: string;
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dataReviewTimeEstimate = `${
    STATUS_ESTIMATES[status as keyof typeof STATUS_ESTIMATES]
  } hours`;

  const hideCancelButton =
    [ADMIN_PROJECT_STATUSES.PROCESSING, NON_ADMIN_PROJECT_STATUSES.UPLOAD_PROCESSING].includes(
      projectStatus
    ) || status === DESIGN_STATUS.designInProgress;

  return (
    <div className="DesignStatus-Wrapper">
      <DataReviewIcon className="DesignStatus-ProcessingIcon" />
      <div className="DesignStatus-Title">{t('DesignStatusDisplay.dataReview')}</div>
      <p className="DesignStatus-Timeframe">
        {t('DesignStatusDisplay.timeframe')}{' '}
        <span className="DesignStatus-Value">{dataReviewTimeEstimate}</span>
      </p>
      <ActionButtons
        handleClick={() => handleMyProjectsButtonClick(history)}
        handleCancel={handleModalOpen}
        hideCancelButton={hideCancelButton}
      />
    </div>
  );
};

export default DataReview;
