import { DashboardModal, useUppy } from '@uppy/react';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Uppy, { UploadResult } from '@uppy/core';
import '@uppy/core/dist/style.css';
import Dashboard from '@uppy/dashboard';
import '@uppy/dashboard/dist/style.css';
import Tus from '@uppy/tus';
import {
  SUPPORTED_FILES_NOTE,
  SUPPORTED_FILE_TYPES,
} from 'screens/Albums/ProjectCategory/constants';

import { RootState } from 'state/store/store';

import { EventPlugin, PhotoMetaPlugin, XHRUploadWithRetryPlugin } from 'helpers/utils/uppy';

import 'styles/overwrites/uppy.scss';

import { UploadDashboardModalProps } from './types';

const UploadDashboardModal = ({
  isModalVisible,
  handleUploadComplete,
  toggleIsModalVisible,
  noteId,
  categoryId,
  endpoint,
  fieldId,
  sectionId,
}: UploadDashboardModalProps) => {
  const { projectId } = useParams<{ projectId: string }>();
  const { currentUser } = useSelector((state: RootState) => state.users);
  const currentAccessToken = localStorage.getItem('accessToken');

  const uppyOptions = useMemo(() => {
    return {
      debug: true,
      autoProceed: false,
      restrictions: {
        maxFileSize: 10000000,
        maxNumberOfFiles: 10,
        minNumberOfFiles: 1,
        allowedFileTypes: SUPPORTED_FILE_TYPES.IMAGE_AND_VIDEO,
      },
    };
  }, []);

  const onComplete = useCallback(
    (result: UploadResult) => {
      if (!result.failed.length) {
        handleUploadComplete(result);
        toggleIsModalVisible();
      }
    },
    [handleUploadComplete, toggleIsModalVisible]
  );

  const eventPluginOptions = useMemo(() => {
    return {
      onComplete,
    };
  }, [onComplete]);

  const uppy = useUppy(() => {
    const tempUppy = new Uppy(uppyOptions)
      .use(Dashboard, {
        inline: false,
        showProgressDetails: true,
        browserBackButtonClose: false,
        waitForThumbnailsBeforeUpload: true,
      })
      .use(EventPlugin, eventPluginOptions)
      .use(PhotoMetaPlugin)

      // @ts-ignore
      .on('thumbnail:all-generated', () => {
        const files = uppy.getFiles();
        files.forEach(({ id, preview }) => {
          uppy.setFileMeta(id, {
            thumbnailUrl: preview,
          });
        });
      });
    if (noteId) {
      tempUppy.use(Tus, {
        chunkSize: 5 * 1024 * 1024,
        endpoint,
        headers: {
          Authorization: `Bearer ${currentAccessToken}`,
        },
        limit: 1,
        removeFingerprintOnSuccess: true,
        storeFingerprintForResuming: false,
      });
    } else {
      tempUppy.use(XHRUploadWithRetryPlugin, {
        endpoint,
        headers: {
          Authorization: `Bearer ${currentAccessToken}`,
        },
        timeout: 0,
        allowedMetaFields: null, // send all metadata fields
      });
    }
    return tempUppy;
  });

  useEffect(() => {
    uppy.setMeta({
      note_id: noteId,
      project_id: projectId,
      user_id: currentUser!.id,
      category_id: categoryId,
      field_id: fieldId,
      section_id: sectionId,
    });
  }, [uppy, projectId, currentUser, noteId, categoryId, sectionId, fieldId]);

  useEffect(() => {
    uppy.getPlugin('EventPlugin')?.setOptions(eventPluginOptions);
  }, [eventPluginOptions, uppy]);

  useEffect(() => {
    if (!isModalVisible && uppy) {
      uppy.cancelAll();
    }
  }, [isModalVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DashboardModal
      uppy={uppy}
      closeModalOnClickOutside
      open={isModalVisible}
      onRequestClose={toggleIsModalVisible}
      note={SUPPORTED_FILES_NOTE.ALL}
      proudlyDisplayPoweredByUppy={false}
    />
  );
};

export default UploadDashboardModal;
