import { i18n } from 'helpers/utils/translations';

export const PAGE_TITLES = {
  SCANIFLY_3D_SETTINGS: i18n.t('PageTitles.scanifly3dSettings'),
  MY_PROJECTS: i18n.t('PageTitles.myProjects'),
  ACCOUNT_SETTINGS: i18n.t('PageTitles.accountSettings'),
  ACCOUNT_COMPANY_SETTINGS: i18n.t('PageTitles.accountCompanySettings'),
  TEAM_LIST: i18n.t('PageTitles.teamList'),
  NEW_PROJECT_CUSTOMER_INFO: i18n.t('PageTitles.newProjectCustomerInfo'),
  NEW_PROJECT_CONSUMPTION_DATA: i18n.t('PageTitles.newProjectConsumptionData'),
  PROJECT_OVERVIEW: i18n.t('PageTitles.projectOverview'),
  CUSTOMER_INFO: i18n.t('PageTitles.customerInfo'),
  CONSUMPTION_DATA: i18n.t('PageTitles.consumptionData'),
  DESIGNS: i18n.t('PageTitles.designs'),
  USER_PROFILE: i18n.t('PageTitles.userProfile'),
  BILLING: i18n.t('PageTitles.billing'),
  BILLING_CREDITS: i18n.t('PageTitles.billingCredits'),
  SITE_DATA: i18n.t('PageTitles.siteData'),
  PROJECT_CATEGORIES: i18n.t('PageTitles.ProjectCategories'),
  COMMENTS: i18n.t('PageTitles.comments'),
  ALBUMS: i18n.t('PageTitles.albums'),
  ACCOUNT_LOGIN_SECURITY: i18n.t('PageTitles.accountLoginSecurity'),
  SCANIFLY_ADMIN_MENU: i18n.t('PageTitles.scaniflyAdminConsole'),
  SCANIFLY_ADMIN_ACCOUNT_MANAGER: i18n.t('PageTitles.scaniflyAdminAccountManager'),
  SCANIFLY_ADMIN_ACCOUNTING: i18n.t('PageTitles.scaniflyAdminAccounting'),
  SCANIFLY_ADMIN_COMPANY_CREDIT_HISTORY: i18n.t('PageTitles.scaniflyAdminCompanyCreditHistory'),
  SCANIFLY_ADMIN_ALL_CREDIT_HISTORY: i18n.t('PageTitles.scaniflyAdminAllCreditHistory'),
  SCANIFLY_ADMIN_CREDIT_HISTORY: i18n.t('PageTitles.scaniflyAdminCreditHistory'),
  SCANIFLY_ADMIN_REFERENCE_MEDIA: i18n.t('PageTitles.scaniflyAdminReferenceMedia'),
  SCANIFLY_ADMIN_USERS: i18n.t('PageTitles.scaniflyAdminUsers'),
  SCANIFLY_ADMIN_USERS_CUSTOMER: i18n.t('PageTitles.scaniflyAdminUsersCustomer'),
  SCANIFLY_ADMIN_CS_UPLOAD: i18n.t('PageTitles.scaniflyAdminCsUpload'),
  SCANIFLY_ADMIN_PROJECTS: i18n.t('PageTitles.scaniflyAdminProjects'),
  SCANIFLY_ADMIN_PROJECTS_CUSTOMER: i18n.t('PageTitles.scaniflyAdminProjectsCustomer'),
  SCANIFLY_ADMIN_PROJECT_USER: i18n.t('PageTitles.scaniflyAdminProjectsUser'),
  SCANIFLY_ADMIN_ACCESS_TOKENS: i18n.t('PageTitles.scaniflyAdminAccessTokens'),
  SCANIFLY_ADMIN_DESIGN_SERVICES_QUEUE: i18n.t('PageTitles.scaniflyAdminDesignServicesQueue'),
  SCANIFLY_INFO: i18n.t('PageTitles.scaniflyInfo'),
  PROJECT_INFO: i18n.t('PageTitles.projectAdmin'),
  COMPANY_SETTINGS: i18n.t('PageTitles.companySettings'),
  DESIGN_SERVICES_QUEUE: i18n.t('PageTitles.designServicesQueue'),
  ORDER_A_DESIGN: i18n.t('PageTitles.orderADesign'),
  ORDER_FORM: i18n.t('PageTitles.orderForm'),
  DELIVERABLES: i18n.t('PageTitles.deliverables'),
  DESIGN_STATUS: i18n.t('PageTitles.designStatus'),
  CHECKLISTS: i18n.t('PageTitles.checklists'),
  EDIT_CHECKLIST_TEMPLATE: i18n.t('PageTitles.editChecklistTemplate'),
  INTEGRATIONS: i18n.t('PageTitles.integrations'),
  CHECKLIST_MANAGER: i18n.t('PageTitles.checklistManager'),
  CHECKLIST_ADMIN_MANAGER: i18n.t('PageTitles.checklistAdminManager'),
  MAINTENANCE: i18n.t('PageTitles.maintenance'),
  ADMIN_CREDIT_HISTORY: i18n.t('PageTitles.adminCreditHistory'),
};
