import { useTranslation } from 'react-i18next';

import { TIER_LEVEL } from '@cpm/scanifly-shared-data';
import { Card } from 'antd';
import cn from 'classnames';

import styled from 'styled-components';

import { SidebarTag, Tooltip } from 'components';
import { TagTypes } from 'components/SidebarTag/constants';

import breakpoints from 'helpers/constants/breakpoints';
import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';

import { ReactComponent as FilledCheckmarkIcon } from 'assets/icons/checkmark-filled.svg';
import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark.svg';
import { ReactComponent as CircleIcon } from 'assets/icons/circle.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock-icon.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/triangle-exclamation-solid.svg';
import { DesignOption } from 'screens/DesignServices/constants';
import './SelectDesignButton.scss';

type SelectDesignButtonProps = {
  option?: DesignOption;
  selected: { tierLevel: TIER_LEVEL; isExpedited: boolean } | null;
  handleSelect: (serviceType: { tierLevel: TIER_LEVEL; isExpedited: boolean }) => void;
  hasNewTag?: boolean;
  isExpedited?: boolean;
  id: string;
};

const StyledDescription = styled.div`
  font-size: ${fontSizes.extraExtraSmall};
  color: ${colors.darkGray};
  margin-top: 0.5rem;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-basis: 33%;
  flex-grow: 1;

  @media (max-width: ${breakpoints.xxl}) {
    flex-basis: 55%;
  }
`;

const StyledDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-basis: 33%;
  flex-grow: 1;

  @media (max-width: ${breakpoints.xxl}) {
    flex-basis: 10%;
  }
`;

const StyledWarningIcon = styled(WarningIcon)`
  height: 1.75rem;
  margin-top: 0.5rem;
`;

const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SelectDesignButton = ({
  option,
  selected,
  handleSelect,
  hasNewTag = false,
  isExpedited = false,
  id,
}: SelectDesignButtonProps) => {
  const { t } = useTranslation();

  if (!option) return null;

  const isSelected =
    selected?.tierLevel === option.tierLevel &&
    selected?.isExpedited === (option?.isExpedited ?? false);

  return (
    <div
      role="button"
      onClick={() => {
        if (option.tierLevel) {
          handleSelect({ tierLevel: option.tierLevel, isExpedited });
        }
      }}
      onKeyDown={() => {}}
      tabIndex={0}
      data-testid={id}
    >
      <Card
        hoverable
        className={cn('SelectDesignButton-MainWrapper', {
          'SelectDesignButton--active': isSelected,
        })}
      >
        <StyledTitleWrapper>
          <div>
            <div className="SelectDesignButton-ServiceWrapper">
              <div className="SelectDesignButton-ServiceType">{`${option.serviceType}`}</div>{' '}
              <span className="SelectDesignButton-Expedited">
                {isExpedited ? t('DesignService.expedited') : ''}
              </span>
              <StyledIconContainer>
                {hasNewTag ? <SidebarTag text="NEW" type={TagTypes.new} /> : null}
                {option?.disclaimerText ? (
                  <Tooltip bottom={false} title={option?.disclaimerText}>
                    <StyledWarningIcon />
                  </Tooltip>
                ) : null}
              </StyledIconContainer>
            </div>
            <div className="SelectDesignButton-TimeEstimate">
              <ClockIcon className="SelectDesignButton-ClockIcon" />
              <span>
                <span className={isExpedited ? 'SelectDesignButton-Time' : ''}>
                  {option.timeEstimate}{' '}
                  {isExpedited ? t('DesignService.minute') : t('DesignService.hour')}
                </span>{' '}
                {t('DesignService.turnaround')}
              </span>
            </div>
            {option.tierLevel === TIER_LEVEL.planSetForLarge ? (
              <StyledDescription>{t('DesignService.extraChargesDisclaimer')}</StyledDescription>
            ) : null}
          </div>
        </StyledTitleWrapper>
        <div className="SelectDesignButton-FlexSection">
          <p className="SelectDesignButton-CreditCount">{option.credits}</p>
          <p>{t('DesignService.credits')}</p>
        </div>
        <StyledDescriptionWrapper>
          <div>
            {option.specifications.map((spec) => {
              return (
                <p className="SelectDesignButton-Specs" key={spec}>
                  <CheckmarkIcon className="SelectDesignButton-CheckmarkIcon" /> {`${spec}`}
                </p>
              );
            })}
          </div>
        </StyledDescriptionWrapper>
        <div className="SelectDesignButton-FlexSection">
          {isSelected ? (
            <FilledCheckmarkIcon className="SelectDesignButton-FilledCheckmarkIcon" />
          ) : (
            <CircleIcon className="SelectDesignButton-CircleIcon" />
          )}
        </div>
      </Card>
    </div>
  );
};

export default SelectDesignButton;
