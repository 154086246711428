import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';

import { Button, Space, Spin, Tooltip } from 'antd';
import cn from 'classnames';
import MapWrapper from 'screens/MapWrapper';
import { ProjectDesignData } from 'types';

import { companyActiveSubscriptionRequested } from 'state/slices/admin/adminSubscriptionsSlice';
import { projectDesignsRequested } from 'state/slices/projectDesignsSlice';
import { projectRequested, userResubmittedProject } from 'state/slices/projectSlice';
import { AppDispatch, RootState } from 'state/store/store';

import { ConditionalWrapper } from 'components';

import { ACCESS } from 'helpers/constants/access';
import { DRONE_IMAGES } from 'helpers/constants/categories';
import { MAX_DESIGNS } from 'helpers/constants/maxDesigns';
import { PROJECT_STATUSES } from 'helpers/constants/projectStatuses';
import {
  draftProjectCategoryRoute,
  scaniflyAdminDraftProjectCategoryRoute,
} from 'helpers/constants/routes';
import useFeatureToggle from 'helpers/hooks/useFeatureToggle';
import usePermissions from 'helpers/hooks/usePermissions';
import useToggle from 'helpers/hooks/useToggle';
import { thumbnail } from 'helpers/utils/mapbox';
import { renderProjectType } from 'helpers/utils/renderProjectType';

import { ReactComponent as CompanyInfoIcon } from 'assets/company-info.svg';
import { ReactComponent as Drone } from 'assets/drone-small.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/home-icon.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as NoDesignsDrone } from 'assets/onsite-drone.svg';
import { ReactComponent as NoDesignsSatellite } from 'assets/remote-satelite.svg';
import { ReactComponent as Satellite } from 'assets/satellite-small.svg';

import { getAnnualUsage } from '../CustomerInfo/CustomerInfoUsage/helpers';
import ProjectTile from '../ProjectsList/ProjectTile';
import RemoteOnsiteModal from '../RemoteOnsiteModal/RemoteOnsiteModal';
import { DESIGN_TYPES, MODALS, VIEWS } from './constants';
import './Designs.scss';
import DesignsModals from './DesignsModals';
import DesignTile from './DesignTile';
import { isMaxDesignLimitReached } from './helpers';
import InfoView from './InfoView';
import RetryAlert from './RetryAlert/RetryAlert';
import ThreeDView from './ThreeDView';

const Designs = ({ isAdminRoute = false }: { isAdminRoute: boolean }) => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { isSimpleDesignOrSalesManager, isScaniflyAdmin, isDesignServiceProvider } =
    usePermissions();
  const { project, isResubmissionLoading, resubmissionError } = useSelector(
    (state: RootState) => state.project
  );
  const { projectDesigns, isLoading: designsLoading } = useSelector(
    (state: RootState) => state.projectDesigns
  );
  const { projectId } = useParams<{ projectId: string }>();
  const accessRemoteDesign = useFeatureToggle(ACCESS.REMOTE_DESIGN);
  const [isDesignModalVisible, toggleDesignModal] = useToggle(false);
  const [activeDesignType, setActiveDesignType] = useState<null | string>(null);
  const [modalVisible, setModalVisible] = useState('');
  const [selectedDesign, setSelectedDesign] = useState<ProjectDesignData | null>(null);
  const [viewSelected, setViewSelected] = useState<VIEWS>(VIEWS.THREE_D);
  const { activeSubscription } = useSelector((state: RootState) => state.adminSubscriptions);

  useEffect(() => {
    if (project?.companyId) {
      dispatch(companyActiveSubscriptionRequested(project?.companyId));
    }
  }, [dispatch, isDesignServiceProvider, isScaniflyAdmin, project?.companyId]);

  const canUseRemoteDesignFeature = useMemo(
    () => activeSubscription?.canUseRemoteDesign || accessRemoteDesign,
    [accessRemoteDesign, activeSubscription?.canUseRemoteDesign]
  );

  const isLoading = designsLoading || project?.id !== projectId;

  const isDraft = project?.statusDescription === PROJECT_STATUSES.DRAFT;
  const isMaintenance = project?.statusDescription === PROJECT_STATUSES.MAINTENANCE;
  const isProcessingOrUploading =
    project?.statusDescription === PROJECT_STATUSES.PROCESSING ||
    project?.statusDescription === PROJECT_STATUSES.UPLOADING;
  const isErrored = project?.statusDescription === PROJECT_STATUSES.ERROR;
  const isProcessingOrErrored = isProcessingOrUploading || isErrored;
  const isSubmissionError = project?.statusDescription === PROJECT_STATUSES.SUBMISSION_ERROR;

  const isScaniflyAdminView =
    project?.submittedFor && location.pathname.startsWith('/scanifly-admin');

  const remoteDesigns = useMemo(
    () => projectDesigns.filter((design: ProjectDesignData) => design.remote),
    [projectDesigns]
  );
  const onsiteDesigns = useMemo(
    () => projectDesigns.filter((design: ProjectDesignData) => !design.remote),
    [projectDesigns]
  );
  const totalDesigns = projectDesigns.length;

  const hasUserReachedDesignThreshold = isMaxDesignLimitReached({
    remoteDesigns,
    onsiteDesigns,
    activeDesignType,
    accessRemoteDesign: canUseRemoteDesignFeature,
  });

  const isNewDesignCreationDisabled =
    isSimpleDesignOrSalesManager ||
    hasUserReachedDesignThreshold ||
    ((isProcessingOrErrored || isSubmissionError) &&
      activeDesignType !== DESIGN_TYPES.REMOTE_DESIGN) ||
    (isMaintenance && totalDesigns === 0 && activeDesignType !== DESIGN_TYPES.REMOTE_DESIGN) ||
    (isMaintenance &&
      onsiteDesigns.length === 0 &&
      activeDesignType !== DESIGN_TYPES.REMOTE_DESIGN);

  useEffect(() => {
    dispatch(projectDesignsRequested(projectId));
  }, [dispatch, projectId]);

  useEffect(() => {
    if (!project || project.id !== projectId) {
      dispatch(projectRequested(projectId));
      dispatch(projectDesignsRequested(projectId));
    }
  }, [dispatch, project, projectId]);

  const atLeastOneDesignExists = useMemo(() => projectDesigns.length > 0, [projectDesigns.length]);

  useEffect(() => {
    if (activeDesignType === null) {
      remoteDesigns.length > 0 && onsiteDesigns.length === 0
        ? setActiveDesignType(DESIGN_TYPES.REMOTE_DESIGN)
        : setActiveDesignType(DESIGN_TYPES.ONSITE_DESIGN);
    }
  }, [activeDesignType, onsiteDesigns, remoteDesigns, setActiveDesignType]);

  const isRemoteDesignArrayPopulated = useMemo(() => remoteDesigns.length, [remoteDesigns?.length]);

  const isOnsiteDesignArrayPopulated = useMemo(() => onsiteDesigns.length, [onsiteDesigns?.length]);

  useEffect(() => {
    if (canUseRemoteDesignFeature) {
      activeDesignType === DESIGN_TYPES.REMOTE_DESIGN
        ? setSelectedDesign(remoteDesigns[0])
        : setSelectedDesign(onsiteDesigns[0]);
    } else {
      setSelectedDesign(onsiteDesigns[0]);
    }
  }, [onsiteDesigns, remoteDesigns, activeDesignType, canUseRemoteDesignFeature]);

  const handleActiveDesigntype = (type: string) => {
    type === DESIGN_TYPES.REMOTE_DESIGN
      ? setActiveDesignType(DESIGN_TYPES.REMOTE_DESIGN)
      : setActiveDesignType(DESIGN_TYPES.ONSITE_DESIGN);
  };

  const handleDesignSelect = useCallback(
    (designId: string) => {
      setSelectedDesign(projectDesigns.filter(({ id }) => id === designId)[0]);
    },
    [projectDesigns]
  );

  const handleModalClose = useCallback(() => {
    setModalVisible('');
  }, []);

  const getDraftProjectCategoryRoute = useCallback(() => {
    return isAdminRoute
      ? scaniflyAdminDraftProjectCategoryRoute(projectId, DRONE_IMAGES)
      : draftProjectCategoryRoute(projectId, DRONE_IMAGES);
  }, [isAdminRoute, projectId]);

  const handleAddNewDesign = useCallback(() => {
    if (!isNewDesignCreationDisabled) {
      if (canUseRemoteDesignFeature) {
        toggleDesignModal();
      } else {
        setModalVisible(MODALS.ADD_NEW_DESIGN);
      }
    } else {
      history.push(getDraftProjectCategoryRoute(), { from: location.pathname });
    }
  }, [
    isNewDesignCreationDisabled,
    canUseRemoteDesignFeature,
    toggleDesignModal,
    history,
    getDraftProjectCategoryRoute,
    location.pathname,
  ]);

  const handleResubmit = useCallback(() => {
    dispatch(
      userResubmittedProject({
        projectId,
      })
    );
  }, [dispatch, projectId]);

  const getTooltipTitle = useMemo(() => {
    if (hasUserReachedDesignThreshold) {
      return t('Designs.reachedMaxDesignLimit');
    }
    if (isProcessingOrUploading) {
      return t('Designs.processing');
    }
    if (isErrored) {
      return t('Designs.processingError');
    }
    if (isSubmissionError) {
      return t('Designs.submissionError');
    }
    return null;
  }, [hasUserReachedDesignThreshold, isErrored, isProcessingOrUploading, isSubmissionError, t]);

  const imageUrl = useMemo(
    () => (project ? thumbnail(project.geolocation.latitude, project.geolocation.longitude) : ''),
    [project]
  );
  const thumbnailUrl = useMemo(
    () =>
      project
        ? project.thumbnailUrl ||
          thumbnail(project.geolocation.latitude, project.geolocation.longitude, true)
        : '',
    [project]
  );

  const pin = useMemo(() => {
    if (!project?.geolocation) {
      return [];
    }

    return [
      {
        location: project.geolocation,
        id: '1',
      },
    ];
  }, [project]);

  const currentDesignCount = useMemo(() => {
    if (!canUseRemoteDesignFeature) {
      return onsiteDesigns.length;
    } else {
      if (activeDesignType === DESIGN_TYPES.REMOTE_DESIGN) {
        return remoteDesigns.length;
      }
      return onsiteDesigns.length;
    }
  }, [canUseRemoteDesignFeature, activeDesignType, onsiteDesigns, remoteDesigns]);

  const { showOnsiteDesigns, showRemoteDesigns } = useMemo(() => {
    const showOnsiteDesigns =
      canUseRemoteDesignFeature && !isLoading && activeDesignType === DESIGN_TYPES.ONSITE_DESIGN;
    const showRemoteDesigns =
      canUseRemoteDesignFeature && !isLoading && activeDesignType === DESIGN_TYPES.REMOTE_DESIGN;

    return { showOnsiteDesigns, showRemoteDesigns };
  }, [canUseRemoteDesignFeature, isLoading, activeDesignType]);

  const showNoDesignsUI = useMemo(() => {
    if (isLoading) {
      return <Spin size="large" className="Designs-Spinner" />;
    } else {
      if (activeDesignType === DESIGN_TYPES.REMOTE_DESIGN && !isRemoteDesignArrayPopulated) {
        return (
          <div className="Designs-NoDesigns">
            <NoDesignsSatellite />
            {t('RemoteOnsiteDesign.noDesigns')}
          </div>
        );
      } else if (activeDesignType === DESIGN_TYPES.ONSITE_DESIGN && !isOnsiteDesignArrayPopulated) {
        if (isProcessingOrErrored || isSubmissionError) {
          return null;
        }
        return (
          <div className="Designs-NoDesigns">
            <NoDesignsDrone />
            {t('RemoteOnsiteDesign.noDesigns')}
          </div>
        );
      }
    }
  }, [
    activeDesignType,
    isLoading,
    isOnsiteDesignArrayPopulated,
    isProcessingOrErrored,
    isRemoteDesignArrayPopulated,
    isSubmissionError,
    t,
  ]);

  const show3dView = useMemo(
    () => atLeastOneDesignExists && viewSelected === VIEWS.THREE_D && selectedDesign?.id,
    [atLeastOneDesignExists, viewSelected, selectedDesign?.id]
  );
  const showInfoView = useMemo(
    () => atLeastOneDesignExists && imageUrl && viewSelected === VIEWS.INFO && !!selectedDesign?.id,
    [atLeastOneDesignExists, imageUrl, viewSelected, selectedDesign?.id]
  );

  return (
    <>
      <DesignsModals
        designs={projectDesigns}
        modalVisible={modalVisible}
        handleModalClose={handleModalClose}
        selectedDesign={selectedDesign || {}}
      />
      <RemoteOnsiteModal
        isDesignModalVisible={isDesignModalVisible}
        handleModalClose={handleModalClose}
        toggleDesignModal={toggleDesignModal}
        newProject={false}
      />
      <div
        className={cn('Designs', {
          'Designs--Map': atLeastOneDesignExists,
        })}
      >
        <MapWrapper pins={pin} isClickable={false} hidden={!!atLeastOneDesignExists}>
          <div className="Designs-Container">
            {isScaniflyAdminView && (
              <div className="Designs-SubmittedFor">
                {t('Designs.submittedFor', {
                  firstName: project.submittedFor?.firstName,
                  lastName: project.submittedFor?.lastName,
                  companyName: project.submittedFor?.company.name,
                })}
              </div>
            )}
            <div className="Designs-InfoWrapper">
              <h2 className="Designs-ProjectName">{project?.name}</h2>
              <p className="Designs-CountDisplay">{`${currentDesignCount}/${MAX_DESIGNS} Designs`}</p>
            </div>
            <div className="Designs-ButtonWrapper">
              {project?.type && !isDraft && (
                <div className="Designs-ProjectType">
                  <span className="Designs-ProjectType-Title">{t('Designs.projectType')}</span>
                  <HomeIcon />
                  {renderProjectType(project.type)}
                </div>
              )}
              <ConditionalWrapper
                condition={isNewDesignCreationDisabled}
                wrapper={(children) => (
                  <Tooltip placement="top" title={getTooltipTitle}>
                    {children}
                  </Tooltip>
                )}
              >
                <div>
                  <Button
                    className="Button--Blue Designs-NewButton"
                    onClick={() => handleAddNewDesign()}
                    disabled={isNewDesignCreationDisabled}
                    aria-disabled={isNewDesignCreationDisabled}
                    data-testid="new-design-button"
                    id="add-design-button"
                  >
                    <PlusIcon />
                    {t('Designs.newDesign')}
                  </Button>
                </div>
              </ConditionalWrapper>
            </div>
            {isSubmissionError && (
              <RetryAlert
                error={resubmissionError ?? ''}
                handleResubmit={handleResubmit}
                isResubmissionLoading={isResubmissionLoading}
              />
            )}
            {canUseRemoteDesignFeature && (
              <div className="Designs-DesignsTypes">
                <button
                  onClick={() => handleActiveDesigntype(DESIGN_TYPES.REMOTE_DESIGN)}
                  className={activeDesignType === DESIGN_TYPES.REMOTE_DESIGN ? 'active' : ''}
                >
                  <Satellite />
                  {t('RemoteOnsiteDesign.remoteDesign')}
                </button>
                <button
                  onClick={() => handleActiveDesigntype(DESIGN_TYPES.ONSITE_DESIGN)}
                  className={activeDesignType === DESIGN_TYPES.ONSITE_DESIGN ? 'active' : ''}
                >
                  <Drone />
                  {t('RemoteOnsiteDesign.onsiteDesign')}
                </button>
              </div>
            )}
            <div
              className={cn('Designs-DesignsList', {
                'Designs-DesignsList--Project':
                  !atLeastOneDesignExists && (isProcessingOrErrored || isSubmissionError),
              })}
            >
              {isLoading && <Spin size="large" className="Designs-Spinner" />}
              {!isLoading &&
                showRemoteDesigns &&
                (remoteDesigns.length
                  ? remoteDesigns.map((design, index) => (
                      <DesignTile
                        design={design}
                        projectId={projectId}
                        thumbnailUrl={thumbnailUrl}
                        id={`remote-design-${index}`}
                        key={design.id}
                        isSelected={design.id === selectedDesign?.id}
                        selectedView={viewSelected}
                        onSelect={handleDesignSelect}
                        onViewChange={setViewSelected}
                        setModalVisible={setModalVisible}
                        hasUserReachedDesignThreshold={hasUserReachedDesignThreshold}
                        projectDesigns={projectDesigns}
                        usage={
                          project &&
                          getAnnualUsage(
                            project.kwh.some((usage: number) => usage > 0)
                              ? project.kwh
                              : project.monthlyUsage
                          )
                        }
                      />
                    ))
                  : showNoDesignsUI)}
              {!isLoading &&
                showOnsiteDesigns &&
                (onsiteDesigns.length
                  ? onsiteDesigns.map((design, index) => (
                      <DesignTile
                        design={design}
                        projectId={projectId}
                        thumbnailUrl={thumbnailUrl}
                        key={design.id}
                        id={`onsite-design-${index}`}
                        isSelected={design.id === selectedDesign?.id}
                        selectedView={viewSelected}
                        onSelect={handleDesignSelect}
                        onViewChange={setViewSelected}
                        setModalVisible={setModalVisible}
                        projectDesigns={projectDesigns}
                        hasUserReachedDesignThreshold={hasUserReachedDesignThreshold}
                        usage={
                          project &&
                          getAnnualUsage(
                            project.kwh.some((usage: number) => usage > 0)
                              ? project.kwh
                              : project.monthlyUsage
                          )
                        }
                      />
                    ))
                  : showNoDesignsUI)}
              {!canUseRemoteDesignFeature &&
                !isLoading &&
                onsiteDesigns.length > 0 &&
                onsiteDesigns.map((design, index) => (
                  <DesignTile
                    design={design}
                    projectId={projectId}
                    thumbnailUrl={thumbnailUrl}
                    key={design.id}
                    id={`onsite-design-${index}`}
                    isSelected={design.id === selectedDesign?.id}
                    selectedView={viewSelected}
                    onSelect={handleDesignSelect}
                    onViewChange={setViewSelected}
                    setModalVisible={setModalVisible}
                    projectDesigns={projectDesigns}
                    hasUserReachedDesignThreshold={hasUserReachedDesignThreshold}
                    usage={
                      project &&
                      getAnnualUsage(
                        project.kwh.some((usage: number) => usage > 0)
                          ? project.kwh
                          : project.monthlyUsage
                      )
                    }
                  />
                ))}

              {isDraft && (
                /* @ts-ignore */
                <Space direction="vertical" size="large">
                  <CompanyInfoIcon className="Designs-Icon" />
                  <p className="Designs-Subtitle">{t('Designs.selectNewDesign')}</p>
                </Space>
              )}
              {(isProcessingOrErrored || isSubmissionError) &&
                ((canUseRemoteDesignFeature && activeDesignType === 'onsiteDesign') ||
                  (!canUseRemoteDesignFeature && activeDesignType === 'remoteDesign')) && (
                  /* @ts-ignore */
                  <Space direction="vertical" size="large">
                    <ProjectTile
                      {...project}
                      onCheck={null}
                      isChecked={undefined}
                      isListView={false}
                      isDesignsPage={true}
                      canUseRemoteDesignFeature={canUseRemoteDesignFeature}
                    />
                  </Space>
                )}
            </div>
          </div>
        </MapWrapper>
        {show3dView ? <ThreeDView projectId={projectId} designId={selectedDesign!.id!} /> : null}
        {showInfoView ? (
          <InfoView
            selectedDesign={selectedDesign!}
            shareUrl={selectedDesign!.shareLinkUrl!}
            thumbnailUrl={imageUrl}
          />
        ) : null}
      </div>
    </>
  );
};

export default Designs;
