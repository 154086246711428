import { useTranslation } from 'react-i18next';

import { FieldInputProps } from 'formik';

import styled from 'styled-components';

import { Divider } from 'components';

import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';
import fontWeights from 'helpers/constants/fontWeights';

import { FORM_CONTROLS } from '../constants';

const StyledDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
  width: 50%;

  input[type='checkbox'] {
    -webkit-appearance: none;
    width: 1.8rem;
    height: 1.8rem;
    background: white;
    border-radius: 0.5rem;
    border: 2px solid ${colors.gray};
    :hover {
      cursor: pointer;
    }
  }

  input[type='checkbox']:checked {
    background: ${colors.green};

    :after {
      content: '✔️';
      text-align: center;
      position: absolute;
      width: calc(2rem - 5px);
      line-height: calc(2rem - 3px);
      font-weight: ${fontWeights.bold};
    }
  }

  label {
    margin-bottom: 0;
    margin-left: 0.5rem;
    font-size: ${fontSizes.small};
    :hover {
      cursor: pointer;
    }
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  :hover {
    cursor: pointer;
  }
`;

const AvailableFeaturesForm = ({
  getFieldProps,
}: {
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
}) => {
  const { t } = useTranslation();
  const featureList = [
    {
      id: 'canUseRemoteDesign',
      props: getFieldProps(FORM_CONTROLS.CAN_USE_REMOTE_DESIGN),
      label: t('Subscription.remoteDesign'),
    },
    {
      id: 'canUseMaintenance',
      props: getFieldProps(FORM_CONTROLS.CAN_USE_MAINTENANCE),
      label: t('Subscription.maintenance'),
    },
    {
      id: 'automatedWireframeSmallOnsite',
      props: getFieldProps(FORM_CONTROLS.AUTOMATED_WIREFRAME_SM_ONSITE),
      label: t('Subscription.automatedWireframeSmallOnsite'),
    },
    {
      id: 'automatedWireframeLargeOnsite',
      props: getFieldProps(FORM_CONTROLS.AUTOMATED_WIREFRAME_LG_ONSITE),
      label: t('Subscription.automatedWireframeLargeOnsite'),
    },
    {
      id: 'automatedWireframePrelim',
      props: getFieldProps(FORM_CONTROLS.AUTOMATED_WIREFRAME_PRELIM),
      label: t('Subscription.automatedWireframePrelim'),
    },
  ];

  return (
    <div>
      <h2 className="Title">{t('Subscription.availableFeatures')}</h2>
      <StyledWrapper>
        {featureList.map(({ id, props, label }) => (
          <StyledDiv key={id}>
            <input
              type="checkbox"
              id={id}
              {...props}
              /* eslint-disable react/prop-types */
              checked={props.value}
            />
            <label htmlFor={id}>{label}</label>
          </StyledDiv>
        ))}
      </StyledWrapper>
      <Divider />
    </div>
  );
};

export default AvailableFeaturesForm;
