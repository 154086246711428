import moment from 'moment';
import { User } from 'types';

import { csvDateFormat } from 'helpers/utils/dateFormat';

import { POSITION_FILTERS, ROLES } from '../constants';

export const handleUsersCSVExport = ({ dataSource }: { dataSource: User[] }) => {
  return dataSource.map((item) => {
    const { roles, position: userPosition, company, createdAt, phone } = item || {};

    const userCompany = Object.keys(item).includes('company') ? { company } : {};

    return {
      ...item,
      roles: roles?.includes('scaniflyAdmin')
        ? ROLES.scaniflyAdmin
        : roles?.includes('admin')
          ? ROLES.admin
          : ROLES.default,
      position: POSITION_FILTERS.find((position) => position.value === userPosition)?.text,
      ...userCompany,
      createdAt: createdAt ? moment(createdAt).format(csvDateFormat) : '-',
      phone: !phone || phone.includes('null') || phone.length < 2 ? '-' : phone,
    };
  });
};
