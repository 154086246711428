export const FORM_CONTROLS = {
  FIRE_SETBACKS: 'fireSetbacks',
  OBSTRUCTION_SETBACKS: 'obstructionSetbacks',
  ATTACHMENT: 'attachment',
  RACKING: 'racking',
  MODULE_INFO: 'moduleInfo',
  INVERTER_INFO: 'inverterInfo',
} as const;

export const initialValues = {
  [FORM_CONTROLS.FIRE_SETBACKS]: {
    ridge: '',
    eave: '',
    rake: '',
    valley: '',
    hip: '',
  },
  [FORM_CONTROLS.OBSTRUCTION_SETBACKS]: {
    vents: '',
    exhaust: '',
    skylights: '',
    parapet: '',
    general: '',
  },
};
