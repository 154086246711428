import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { FEATURE_LIST, getFeatureAvailability } from '@cpm/scanifly-shared-data';
import cn from 'classnames';
import { kebabCase } from 'lodash-es';

import { RootState } from 'state/store/store';

import { ACCESS } from 'helpers/constants/access';
import { PAGE_TITLES } from 'helpers/constants/pageTitles';
import {
  account3dSettingsRoute,
  accountCompanyInfoRoute,
  accountLoginSecurityRoute,
  accountPaymentsInvoicesRoute,
  accountProfileRoute,
  integrationsRoute,
  teamListRoute,
} from 'helpers/constants/routes';
import useFeatureToggle from 'helpers/hooks/useFeatureToggle';
import usePermissions from 'helpers/hooks/usePermissions';

import { ReactComponent as ThreeDSettingsIcon } from 'assets/3dsettings.svg';
import { ReactComponent as AccountProfileIcon } from 'assets/account-profile-icon.svg';
import { ReactComponent as PaymentsInvoicesIcon } from 'assets/billing-icon.svg';
import { ReactComponent as CompanySettingsIcon } from 'assets/company-settings-icon.svg';
import { ReactComponent as LoginSecurityIcon } from 'assets/login-security-icon.svg';
import { ReactComponent as PlugIcon } from 'assets/plug-integrations.svg';
import { ReactComponent as TeamIcon } from 'assets/team-icon.svg';

import './Account.scss';

const Account = () => {
  const {
    isAdmin,
    isScaniflyAdmin,
    hasBillingPermissions,
    isDesignServiceProvider,
    hasScanifly3DSettingsPermissions,
    isSimpleDesignOrSalesManager,
  } = usePermissions();
  const { company } = useSelector((state: RootState) => state.company);
  const designServicesAccess = getFeatureAvailability(
    isScaniflyAdmin,
    FEATURE_LIST.DESIGN_SERVICES,
    company?.pricingTier
  );

  const googleDriveAccess = useFeatureToggle(ACCESS.GOOGLE_DRIVE);

  return (
    <div className="Account-Container">
      <div className="Account-Wrapper">
        <Link
          to={accountProfileRoute()}
          className="Account-Button"
          id={`Account-Menu-Button-${kebabCase(PAGE_TITLES.USER_PROFILE)}`}
        >
          <AccountProfileIcon className="Account-Button-Icon Account-Button-Icon--AccountProfile" />
          <span className="Account-Button-Text">{PAGE_TITLES.USER_PROFILE}</span>
        </Link>
        <Link
          to={accountCompanyInfoRoute()}
          className={cn('Account-Button', { Hidden: !isAdmin })}
          id={`Account-Menu-Button-${kebabCase(PAGE_TITLES.COMPANY_SETTINGS)}`}
        >
          <CompanySettingsIcon className="Account-Button-Icon Account-Button-Icon--CompanyInfo" />
          <span className="Account-Button-Text">{PAGE_TITLES.COMPANY_SETTINGS}</span>
        </Link>
        <Link
          to={accountLoginSecurityRoute()}
          className="Account-Button"
          id={`Account-Menu-Button-${kebabCase(PAGE_TITLES.ACCOUNT_LOGIN_SECURITY)}`}
        >
          <LoginSecurityIcon className="Account-Button-Icon Account-Button-Icon--LoginSecurity" />
          <span className="Account-Button-Text">{PAGE_TITLES.ACCOUNT_LOGIN_SECURITY}</span>
        </Link>
        {hasBillingPermissions && !isDesignServiceProvider && !isSimpleDesignOrSalesManager ? (
          <Link
            to={accountPaymentsInvoicesRoute()}
            className={'Account-Button'}
            id={`Account-Menu-Button-${kebabCase(PAGE_TITLES.BILLING)}`}
          >
            <PaymentsInvoicesIcon className="Account-Button-Icon Account-Button-Icon--PaymentsInvoices" />
            <span className="Account-Button-Text">
              {designServicesAccess ? PAGE_TITLES.BILLING_CREDITS : PAGE_TITLES.BILLING}
            </span>
          </Link>
        ) : null}

        {!isSimpleDesignOrSalesManager && (
          <Link
            to={teamListRoute()}
            className={cn('Account-Button')}
            id={`Account-Menu-Button-${kebabCase(PAGE_TITLES.TEAM_LIST)}`}
          >
            <TeamIcon className="Account-Button-Icon Account-Button-Icon--TeamIcon" />
            <span className="Account-Button-Text">{PAGE_TITLES.TEAM_LIST}</span>
          </Link>
        )}
        {googleDriveAccess && (
          <Link
            to={integrationsRoute()}
            className={cn('Account-Button')}
            id={`Account-Menu-Button-${kebabCase(PAGE_TITLES.INTEGRATIONS)}`}
          >
            <PlugIcon className="Account-Button-Icon Account-Button-Icon--PlugIcon" />
            <span className="Account-Button-Text">{PAGE_TITLES.INTEGRATIONS}</span>
          </Link>
        )}
        {hasScanifly3DSettingsPermissions && (
          <Link
            to={account3dSettingsRoute()}
            className={cn('Account-Button')}
            id={`Account-Menu-Button-${kebabCase(PAGE_TITLES.SCANIFLY_3D_SETTINGS)}`}
          >
            <ThreeDSettingsIcon
              className="Account-Button-Icon Account-Button-Icon"
              id="ThreeDSettingsIcon"
            />
            <span className="Account-Button-Text">{PAGE_TITLES.SCANIFLY_3D_SETTINGS}</span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Account;
