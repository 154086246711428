import { Dispatch, SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { COST_PER_CREDIT, FEATURE_LIST, getFeatureAvailability } from '@cpm/scanifly-shared-data';
import { Button, Input } from 'antd';
import { useFormik } from 'formik';
import { Subscription } from 'types';

import { orderCreditsRequested } from 'state/slices/designServices/orderDesignServiceCreditsSlice';
import { AppDispatch, RootState } from 'state/store/store';

import { EmailLink } from 'components';
import {
  CreditDisplayRow,
  CreditDisplayWithNote,
} from 'components/BillingAndCredits/CreditDisplayRow/CreditDisplayRow';
import {
  Note,
  NoteWithLink,
  SectionHeaderWithNote,
  SubHeader,
} from 'components/BillingAndCredits/SectionHeader/SectionHeader';
import { ServiceCreditPair } from 'components/BillingAndCredits/ServiceCreditPair/ServiceCreditPair';

import { ACCESS } from 'helpers/constants';
import { responseStatus } from 'helpers/constants/responseStatus';
import useFeatureToggle from 'helpers/hooks/useFeatureToggle';
import usePermissions from 'helpers/hooks/usePermissions';

import { DesignServicesCreditList, NearmapCreditList } from '../constants/designServicesCreditList';
import { FORM_CONTROLS_CREDITS } from '../constants/formControls';
import { initialValuesForCredits } from '../constants/initialValues';
import './AccountDesignServicesCredits.scss';
import Notification from './Notification/Notification';
import { validationSchema } from './validationSchema';

export const AccountDesignServicesCredits = ({
  companySubscriptionInfo,
  setRemainingCredits,
}: {
  companySubscriptionInfo: {
    credits: { amount: number };
    subscription: Subscription;
    companyId: string;
  } | null;
  setRemainingCredits: Dispatch<SetStateAction<number | undefined>>;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [creditsDebitedIntoAccount, setCreditsDebitedIntoAccount] = useState(0);
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const { isScaniflyAdmin } = usePermissions();
  const { company } = useSelector((state: RootState) => state.company);
  const designServicesAccess =
    useFeatureToggle(ACCESS.DESIGN_SERVICES_PRELIM) &&
    getFeatureAvailability(isScaniflyAdmin, FEATURE_LIST.DESIGN_SERVICES, company?.pricingTier);

  const { isLoading, error, isSuccess } = useSelector(
    (state: RootState) => state.orderDesignServiceCredits
  );
  const { companyId } = companySubscriptionInfo || {};

  const { getFieldProps, handleSubmit, setFieldValue, resetForm, values } = useFormik<{
    creditCount: string | number;
  }>({
    initialValues: initialValuesForCredits,
    validationSchema,
    onSubmit: () => {
      if (getFieldProps('creditCount').value > 0 && companyId) {
        dispatch(
          orderCreditsRequested({
            companyId: companyId,
            creditCount: getFieldProps('creditCount').value,
            onSuccess,
          })
        );
      }
      resetForm();
    },
  });

  const isOrderButtonDisabled =
    !getFieldProps('creditCount') ||
    getFieldProps('creditCount').value === 0 ||
    getFieldProps('creditCount').value === '';

  const onSuccess = (balance: number, addedCredits = 0) => {
    setRemainingCredits(balance);
    setCreditsDebitedIntoAccount(addedCredits);
  };

  const handleOrder = () => {
    if (!companyId) return;
    handleSubmit();
  };

  const handleNegativeInputValues = (
    event: { target: HTMLInputElement },
    setFieldValue: (
      field: string,
      value: string | number,
      shouldValidate?: boolean | undefined
    ) => void,
    fieldName: keyof typeof FORM_CONTROLS_CREDITS
  ) => {
    if (event?.target?.value === '') {
      setFieldValue(FORM_CONTROLS_CREDITS[fieldName], '');
    } else if (Number(event.target.value) < 0) {
      setFieldValue(FORM_CONTROLS_CREDITS[fieldName], 0);
    } else {
      setFieldValue(FORM_CONTROLS_CREDITS[fieldName], Number(event.target.value));
    }
  };

  return (
    <article>
      <SubHeader>{t('Credits.designServices')}</SubHeader>
      {DesignServicesCreditList(designServicesAccess).map((row, index) => {
        return (
          <CreditDisplayRow key={`row-${index}`}>
            {row?.map((subitem) => {
              return <ServiceCreditPair subitem={subitem} key={subitem.title} />;
            })}
          </CreditDisplayRow>
        );
      })}
      <SubHeader>{t('Credits.nearmap')}</SubHeader>
      {NearmapCreditList.map((row, index) => {
        return (
          <CreditDisplayRow key={`row-${index}`}>
            {row?.map((subitem) => {
              return <ServiceCreditPair subitem={subitem} key={subitem.title} />;
            })}
          </CreditDisplayRow>
        );
      })}
      <>
        <CreditDisplayRow>
          <SectionHeaderWithNote>{t('BillingTitles.purchaseCredits')}</SectionHeaderWithNote>
          <Note>{t('Credits.note')}</Note>
        </CreditDisplayRow>
        <CreditDisplayWithNote>
          <NoteWithLink>
            <Trans i18nKey="Credits.creditDisclaimer" components={[<EmailLink />]} />
          </NoteWithLink>
          <Input
            {...getFieldProps(FORM_CONTROLS_CREDITS.CREDIT_COUNT)}
            type="number"
            min="0"
            step="1"
            placeholder="0"
            id="creditCountForOrder"
            className="DesignServices-CreditInput"
            onKeyDown={(e) => {
              if (isNaN(Number(e.key)) && e.key !== 'Backspace' && e.key !== 'Tab') {
                e.preventDefault();
              }
            }}
            onPaste={(e) => {
              if (isNaN(Number(e.clipboardData.getData('Text')))) {
                e.preventDefault();
              }
            }}
            aria-label={FORM_CONTROLS_CREDITS.CREDIT_COUNT}
            onChange={(ev) => handleNegativeInputValues(ev, setFieldValue, 'CREDIT_COUNT')}
          />
        </CreditDisplayWithNote>
      </>

      <article className="DesignServices-ButtonWrapper">
        {isNotificationVisible && !isLoading ? (
          <div className="DesignServices-Notification">
            <Notification
              creditsDebitedIntoAccount={creditsDebitedIntoAccount}
              response={
                isSuccess
                  ? (responseStatus.success as keyof typeof responseStatus)
                  : (responseStatus.error as keyof typeof responseStatus)
              }
            />
          </div>
        ) : (
          <div></div>
        )}
        <div className="DesignServices-TotalCreditDisplayWrapper">
          <section className="DesignServices-TotalCreditDisplay">
            {t('Credits.total')}:
            <span>
              {`$${(Number(values.creditCount) * COST_PER_CREDIT).toFixed(2)} `.toLocaleString()}
            </span>
          </section>
          <Button
            onClick={() => {
              setIsNotificationVisible(true);
              handleOrder();
            }}
            className="Button--Blue DesignServices-Button"
            disabled={isOrderButtonDisabled}
            loading={isLoading && !error && !isSuccess}
          >
            {t('Credits.orderCredits')}
          </Button>
        </div>
      </article>
    </article>
  );
};
