import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { Subscription } from 'types';

import {
  adminCreateNewSubscription,
  adminFetchActiveSubscription,
  adminFetchAllSubscriptionsOfAGivenCompany,
  adminFetchSelectedSubscription,
  adminUpdateSubscription,
} from 'api/admin/adminService';

import { createErrorNotificationTextForSubscriptionCreation } from 'helpers/utils/createErrorNotificationTextForSubscriptionCreation';
import { openNotification } from 'helpers/utils/openNotification';

const name = 'adminSubscriptions';

export const companyActiveSubscriptionRequested = createAsyncThunk(
  `${name}/activeSubscriptionRequested`,
  async (companyId: string, { rejectWithValue }) => {
    try {
      const response = await adminFetchActiveSubscription(companyId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const companySubscriptionWithIdRequested = createAsyncThunk(
  `${name}/companySubscriptionWithIdRequested`,
  async (selectedSubscriptionId: string, { rejectWithValue }) => {
    try {
      if (selectedSubscriptionId) {
        const response = await adminFetchSelectedSubscription(selectedSubscriptionId);
        return response.data;
      } else {
        return null;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const newSubscriptionCreationRequested = createAsyncThunk(
  `${name}/newSubscriptionCreationRequested`,
  async (
    {
      newSubscription,
      setInformationSaved,
    }: {
      newSubscription: Subscription;
      setInformationSaved: (isSaved: boolean) => void;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await adminCreateNewSubscription(newSubscription);
      setInformationSaved(true);
      openNotification({
        type: 'success',
        title: 'Success!',
        text: 'A new subscription has been successfully created!',
      });
      return response.data;
    } catch (error: any) {
      openNotification({
        type: 'error',
        title: 'Error!',
        text: createErrorNotificationTextForSubscriptionCreation(error),
      });
      return rejectWithValue(error.response.data);
    }
  }
);

export const subscriptionUpdateRequested = createAsyncThunk(
  `${name}/subscriptionUpdateRequested`,
  async (
    {
      subscriptionId,
      updatedSubscription,
      setInformationSaved,
      onUpdateSuccess,
    }: {
      subscriptionId: string;
      updatedSubscription: Subscription;
      setInformationSaved: (isSaved: boolean) => void;
      onUpdateSuccess: () => void;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await adminUpdateSubscription(subscriptionId, updatedSubscription);
      setInformationSaved(true);
      openNotification({
        type: 'success',
        title: 'Success!',
        text: 'Subscription information has been successfully updated!',
      });
      onUpdateSuccess();
      return response.data;
    } catch (error: any) {
      openNotification({
        type: 'error',
        title: 'Error!',
        text: "Something has gone wrong and we couldn't update the subscription information.",
      });
      return rejectWithValue(error.response.data);
    }
  }
);

export const adminCompanyAllSubscriptionsRequested = createAsyncThunk(
  `${name}/adminCompanyAllSubscriptionsRequested`,
  async ({ companyId }: { companyId: string }, { rejectWithValue }) => {
    try {
      const response = await adminFetchAllSubscriptionsOfAGivenCompany(companyId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

type AdminSubscriptionState = {
  selectedSubscription: Subscription | null;
  isSelectedSubscriptionLoading: boolean;
  selectedSubscriptionFetchingError: null | string;
  activeSubscription: null | Subscription;
  isCompanyActiveSubscriptionLoading: boolean;
  subscriptionCreationError: null | string | undefined;
  newContract: null | string;
  isNewContractLoading: boolean;
  activeSubscriptionFetchingError: null | string;
  allSubscriptionsOfAGivenCompany: any[];
  isSubscriptionDataLoading: boolean;
  allSubscriptionsFetchingError: null | string | undefined;
  isNewContactLoading: boolean;
};

const adminSubscriptionsSlice = createSlice({
  name,
  initialState: {
    selectedSubscription: null,
    isSelectedSubscriptionLoading: false,
    selectedSubscriptionFetchingError: null,
    activeSubscription: null,
    isCompanyActiveSubscriptionLoading: false,
    subscriptionCreationError: null,
    newContract: null,
    isNewContractLoading: false,
    activeSubscriptionFetchingError: null,
    allSubscriptionsOfAGivenCompany: [],
    isSubscriptionDataLoading: false,
    allSubscriptionsFetchingError: null,
    isNewContactLoading: false,
  } as AdminSubscriptionState,
  reducers: {
    clearCompanyActiveSubscription: (state) => {
      state.activeSubscription = null;
    },
    resetSelectedSubscription: (state) => {
      state.selectedSubscription = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(companyActiveSubscriptionRequested.pending, (state) => {
      state.isCompanyActiveSubscriptionLoading = true;
    });
    builder.addCase(companyActiveSubscriptionRequested.fulfilled, (state, { payload }) => {
      state.activeSubscription = payload;
      state.activeSubscriptionFetchingError = null;
      state.isCompanyActiveSubscriptionLoading = false;
    });
    builder.addCase(
      companyActiveSubscriptionRequested.rejected,
      (state, { error }: { error: any }) => {
        state.activeSubscriptionFetchingError = error.message;
        state.isCompanyActiveSubscriptionLoading = false;
        state.activeSubscription = null;
      }
    );
    builder.addCase(companySubscriptionWithIdRequested.pending, (state) => {
      state.isSelectedSubscriptionLoading = true;
    });
    builder.addCase(companySubscriptionWithIdRequested.fulfilled, (state, { payload }) => {
      state.selectedSubscription = payload;
      state.selectedSubscriptionFetchingError = null;
      state.isSelectedSubscriptionLoading = false;
    });
    builder.addCase(
      companySubscriptionWithIdRequested.rejected,
      (state, { error }: { error: any }) => {
        state.selectedSubscriptionFetchingError = error.message;
        state.isSelectedSubscriptionLoading = false;
        state.selectedSubscription = null;
      }
    );
    builder.addCase(newSubscriptionCreationRequested.pending, (state) => {
      state.isNewContactLoading = true;
    });
    builder.addCase(newSubscriptionCreationRequested.fulfilled, (state, { payload }) => {
      state.newContract = payload;
      state.subscriptionCreationError = null;
      state.isNewContactLoading = false;
    });
    builder.addCase(newSubscriptionCreationRequested.rejected, (state, { error }) => {
      state.subscriptionCreationError = error.message;
      state.newContract = null;
      state.isNewContactLoading = false;
    });
    builder.addCase(adminCompanyAllSubscriptionsRequested.pending, (state) => {
      state.isSubscriptionDataLoading = true;
      state.allSubscriptionsOfAGivenCompany = [];
      state.activeSubscription = null;
      state.selectedSubscription = null;
    });
    builder.addCase(adminCompanyAllSubscriptionsRequested.fulfilled, (state, { payload }) => {
      state.allSubscriptionsOfAGivenCompany = payload;
      const now = moment.utc().valueOf();
      state.activeSubscription = payload.find(
        (sub: { contractStartDate: moment.MomentInput; contractEndDate: moment.MomentInput }) =>
          moment.utc(sub.contractStartDate).valueOf() <= now &&
          moment.utc(sub.contractEndDate).valueOf() >= now
      );
      state.allSubscriptionsFetchingError = null;
      state.isSubscriptionDataLoading = false;
    });
    builder.addCase(adminCompanyAllSubscriptionsRequested.rejected, (state, { error }) => {
      state.allSubscriptionsFetchingError = error.message;
      state.isSubscriptionDataLoading = false;
      state.allSubscriptionsOfAGivenCompany = [];
      state.activeSubscription = null;
      state.selectedSubscription = null;
    });
  },
});

export const { clearCompanyActiveSubscription, resetSelectedSubscription } =
  adminSubscriptionsSlice.actions;

export default adminSubscriptionsSlice.reducer;
