import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { DESIGN_STATUS, mapSystemSizeValueToType, TIER_LEVEL } from '@cpm/scanifly-shared-data';
import { Card, RadioChangeEvent } from 'antd';
import { useFormik } from 'formik';
import { SIZED_FOR_CATEGORIES } from 'screens/DesignServices/constants';

import {
  defaultProjectDesignCreated,
  projectDesignsRequested,
} from 'state/slices/projectDesignsSlice';
import { AppDispatch, RootState } from 'state/store/store';

import { createDesignServiceRequest } from 'api/designServices/designServicesService';

import { CustomStep, ModalContext } from 'components';

import { openNotification } from 'helpers/utils/openNotification';
import { getInitialFormValues } from 'screens/DesignServices/helpers/getInitialFormValues';
import { getValidationSchema } from 'screens/DesignServices/helpers/getValidationSchema';

import { useTranslation } from 'react-i18next';
import { projectRequested } from 'state/slices/projectSlice';
import ActionButtons from '../ActionButtons/ActionButtons';
import CommentForm from './CommentForm/CommentForm';
import { FORM_CONTROLS, SUBFORM_TITLES } from './constants';
import FormActionButtons from './FormActionButtons/FormActionButtons';
import './FormWrapper.scss';
import { DesignInformation } from './MaxFillForms/DesignInformation';
import ElectricalInfo from './PlanSetForms/ElectricalInfo';
import PreliminaryDesignForm from './PlanSetForms/PreliminaryDesignForm';
import ProjectInfoPlanSet from './PlanSetForms/ProjectInfoPlanSet';
import { StructuralInfo } from './PlanSetForms/StructuralInfo';
import AdditionalInformationForSiteModeling from './SiteModelingForms/AdditionalInformationForSiteModeling';
import ProjectInformationForSiteModeling from './SiteModelingForms/ProjectInformationForSiteModeling';
import ProjectInfoSitePlan from './SitePlanForms/ProjectInfoSitePlan';
import TrueUpDesignForm from './TrueUpForms/TrueUpDesignForm';
import UploadForm from './UploadForm/UploadForm';

type Props = {
  setFormsState: (currentState: string) => void;
  formsState: string;
  selectedDesignInfo: any;
  isDesignSelectionPage: boolean;
  stepNumber: number;
  handleResponse: Function;
  handleCancel: () => void;
  upgrade: boolean;
  isExpedited?: boolean;
};

const FormWrapper = ({
  setFormsState,
  formsState,
  selectedDesignInfo,
  isDesignSelectionPage,
  stepNumber,
  handleResponse,
  handleCancel,
  upgrade,
  isExpedited,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { displayConfirmationModal } = useContext(ModalContext);
  const [formStep, setFormStep] = useState(1);
  const [uploadComplete, setUploadComplete] = useState(false);
  const { projectId } = useParams<{
    projectId: string;
  }>();
  const { tierLevel } = selectedDesignInfo;
  const [filesAlreadyStored, setFilesAlreadyStored] = useState(null);
  const [areNewBuildPlansRequired, setAreNewBuildPlansRequired] = useState<boolean>(false);
  const [commercialZone, setCommercialZone] = useState<boolean>(false);
  const [useExistingDesign, setUseExistingDesign] = useState<boolean>(false);
  const [useExistingDesignForTrueUp, setUseExistingDesignForTrueUp] = useState<boolean>(true);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(false);
  const { projectDesigns } = useSelector((state: RootState) => state.projectDesigns);
  const { serviceRequests } = useSelector((state: RootState) => state.designServiceRequests);
  const { project } = useSelector((state: RootState) => state.project);

  const sitePlanRequestsNotCanceled = serviceRequests?.filter(
    (r) => r.status !== DESIGN_STATUS.canceled && r.tierLevel === TIER_LEVEL.sitePlan
  );

  const designBeingUpgraded =
    sitePlanRequestsNotCanceled?.length === 1
      ? sitePlanRequestsNotCanceled?.[0]
      : sitePlanRequestsNotCanceled &&
        sitePlanRequestsNotCanceled.length > 0 &&
        sitePlanRequestsNotCanceled?.reduce((a, b) => (a?.createdAt > b?.createdAt ? a : b));

  const displayModal = useCallback(() => {
    if (handleNewBuildPlansCancel) {
      displayConfirmationModal({
        actionButtonOnClick: () => {},
        onCancel: handleNewBuildPlansCancel,
        actionButtonLabel: 'OK',
        title: `${t('DesignService.hiThere')} 👋`,
        description: t('DesignService.newBuildPlanDisclaimer'),
      });
    }
  }, [displayConfirmationModal, t]);

  useEffect(() => {
    if (!projectDesigns || projectDesigns[0]?.projectId !== projectId) {
      dispatch(projectDesignsRequested(projectId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!project || projectDesigns[0]?.projectId !== projectId) {
      dispatch(projectRequested(projectId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (uploadComplete) {
      setIsSubmitButtonDisabled(false);
    }
  }, [uploadComplete]);

  const initialValuesForForm = getInitialFormValues({
    tierLevel,
    upgrade,
    previousFormData: designBeingUpgraded ? designBeingUpgraded.form : null,
    useExistingDesign: useExistingDesignForTrueUp,
  });

  const validationSchema = getValidationSchema(tierLevel, useExistingDesignForTrueUp);
  const isWireframeOrMaxFillOrSiteModeling =
    tierLevel === TIER_LEVEL.wireframe ||
    tierLevel === TIER_LEVEL.wireframeForLarge ||
    tierLevel === TIER_LEVEL.maxFill ||
    tierLevel === TIER_LEVEL.siteModeling;

  const status = isWireframeOrMaxFillOrSiteModeling
    ? DESIGN_STATUS.ordered
    : DESIGN_STATUS.dataReview;

  const isPrelim =
    tierLevel === TIER_LEVEL.maxFill ||
    tierLevel === TIER_LEVEL.siteModeling ||
    tierLevel === TIER_LEVEL.planSetForPrelim;
  const newBuildPlans = isPrelim ? { newBuildPlansRequired: areNewBuildPlansRequired } : {};

  const formik = useFormik({
    initialValues: initialValuesForForm,
    validationSchema,
    onSubmit: (values) => {
      const sizedForValue =
        values?.sizedForCategory === SIZED_FOR_CATEGORIES.none
          ? values?.sizedForCategory
          : values?.sizedForValue;

      const formData = {
        ...values,
        ...newBuildPlans,
        fireSetbacks: Object.fromEntries(
          Object.entries(values.fireSetbacks ?? {}).map(([key, value]) => [key, value.toString()])
        ),
        obstructionSetbacks: Object.fromEntries(
          Object.entries(values.obstructionSetbacks ?? {}).map(([key, value]) => [
            key,
            value.toString(),
          ])
        ),
        sizedForValue,
        sizedForCategory: values?.sizedForCategory ?? SIZED_FOR_CATEGORIES.none,
        isCommercialZone: commercialZone,
        negativeTolerance:
          useExistingDesignForTrueUp && values && 'negativeTolerance' in values
            ? values.negativeTolerance
            : null,
        positiveTolerance:
          useExistingDesignForTrueUp && values && 'positiveTolerance' in values
            ? values.positiveTolerance
            : null,
      };

      if (
        tierLevel === TIER_LEVEL.wireframe ||
        tierLevel === TIER_LEVEL.wireframeForLarge ||
        tierLevel === TIER_LEVEL.siteModeling
      ) {
        delete formData.sizedForValue;
      }

      const finalizedValues = {
        projectId,
        status,
        tierLevel,
        systemSize:
          'systemSize' in values ? mapSystemSizeValueToType(values.systemSize) : undefined,
        form: formData,
        upgrade,
        isExpedited,
      };

      if (projectId) {
        setIsSubmitButtonDisabled(true);
        createDesignServiceRequest(finalizedValues)
          .then((response) => {
            handleResponse(response.data);
          })
          .catch((err) => {
            if (err.response?.status === 402) {
              openNotification({
                type: 'error',
                title: 'Error!',
                text: 'Insufficient credits.',
              });
            }
          })
          .finally(() => setIsSubmitButtonDisabled(false));
      }
      // Create default design if the order is prelim && does not already have a default design
      const remoteDesigns = projectDesigns.filter((design) => design.remote === true);
      if (isPrelim && remoteDesigns.length === 0) {
        dispatch(
          defaultProjectDesignCreated({
            projectId: projectId,
            name: `Remote Design 1`,
            remote: true,
          })
        );
      }
    },
  });

  const { setFieldValue, getFieldProps, handleSubmit, values } = formik;

  const handleFilesInAlbum = (e: any) => {
    const val = e.target.value;
    setFilesAlreadyStored(val);
    setUploadComplete(val);
  };

  const handleNewBuildPlans = (e: RadioChangeEvent) => {
    const val = e.target.value;
    setAreNewBuildPlansRequired(val);
    if (e.target.value) {
      displayModal();
    }
    if (val && !uploadComplete) {
      setIsSubmitButtonDisabled(true);
    } else {
      setIsSubmitButtonDisabled(false);
    }
  };

  const handleNewBuildPlansCancel = () => {
    setAreNewBuildPlansRequired(false);
    setIsSubmitButtonDisabled(false);
  };

  const handleCommercialZone = (e: RadioChangeEvent) => {
    const val = e.target.value;
    setCommercialZone(val);
  };

  const handleUseExistingDesign = (e: any) => {
    const val = e.target.value;
    setUseExistingDesign(val);
    if (!val) {
      setFieldValue(FORM_CONTROLS.SELECTED_DESIGN, undefined);
    }
  };

  const handleUseExistingDesignForTrueUp = (e: any) => {
    const val = e.target.value;
    setUseExistingDesignForTrueUp(val);
    if (!val) {
      setFieldValue(FORM_CONTROLS.SELECTED_DESIGN, undefined);
    }

    setFieldValue(FORM_CONTROLS.SIZED_FOR_CATEGORY, val ? 'production' : 'none');
  };

  const formControls = {
    ...formik,
    uploadComplete,
    setUploadComplete,
    handleFilesInAlbum,
    filesAlreadyStored,
    useExistingDesign,
    handleUseExistingDesign,
    useExistingDesignForTrueUp,
    handleUseExistingDesignForTrueUp,
    projectDesigns,
  };

  const TRUE_UP_FORMS = [
    {
      step: 1,
      //@ts-ignore
      content: <TrueUpDesignForm tierLevel={tierLevel} project={project} {...formControls} />,
      title: SUBFORM_TITLES.TRUE_UP,
    },
    {
      step: 2,
      content: <CommentForm {...formControls} />,
      title: SUBFORM_TITLES.ADDITIONAL_INFORMATION,
    },
  ];

  const PLAN_SET_FORMS = [
    {
      step: 1,
      content: (
        <ProjectInfoPlanSet
          {...formControls}
          handleCommercialZone={handleCommercialZone}
          commercialZone={commercialZone}
        />
      ),
      title: SUBFORM_TITLES.PROJECT_INFORMATION,
    },
    {
      step: 2,
      content: <StructuralInfo {...formControls} />,
      title: SUBFORM_TITLES.STRUCTURAL_INFORMATION,
    },
    {
      step: 3,
      content: <ElectricalInfo {...formControls} />,
      title: SUBFORM_TITLES.ELECTRICAL_INFORMATION,
    },
    {
      step: 4,
      //@ts-ignore
      content: <PreliminaryDesignForm {...formControls} />,
      title: SUBFORM_TITLES.PRELIMINARY_DESIGN,
    },
    {
      step: 5,
      content: <UploadForm {...formControls} tierLevel={tierLevel} />,
      title: SUBFORM_TITLES.ADDITIONAL_INFORMATION,
    },
    {
      step: 6,
      content: <CommentForm {...formControls} />,
      title: SUBFORM_TITLES.ADDITIONAL_INFORMATION,
    },
  ];

  const PLAN_SET_FOR_PRELIM_FORMS = [
    {
      step: 1,
      content: (
        <ProjectInfoPlanSet
          {...formControls}
          handleCommercialZone={handleCommercialZone}
          commercialZone={commercialZone}
        />
      ),
      title: SUBFORM_TITLES.PROJECT_INFORMATION,
    },
    {
      step: 2,
      content: <StructuralInfo {...formControls} />,
      title: SUBFORM_TITLES.STRUCTURAL_INFORMATION,
    },
    {
      step: 3,
      content: <ElectricalInfo {...formControls} />,
      title: SUBFORM_TITLES.ELECTRICAL_INFORMATION,
    },
    {
      step: 4,
      //@ts-ignore
      content: <PreliminaryDesignForm {...formControls} />,
      title: SUBFORM_TITLES.REFERENCE_DESIGN,
    },
    {
      step: 5,
      content: <UploadForm {...formControls} tierLevel={tierLevel} />,
      title: SUBFORM_TITLES.ADDITIONAL_INFORMATION,
    },
    {
      step: 6,
      content: <CommentForm {...formControls} />,
      title: SUBFORM_TITLES.ADDITIONAL_INFORMATION,
    },
  ];

  const SITE_PLAN_FORMS = [
    {
      step: 1,
      content: (
        <ProjectInfoSitePlan
          {...formControls}
          handleCommercialZone={handleCommercialZone}
          commercialZone={commercialZone}
        />
      ),
      title: SUBFORM_TITLES.PROJECT_INFORMATION,
    },
    {
      step: 2,
      content: <UploadForm {...formControls} tierLevel={tierLevel} />,
      title: SUBFORM_TITLES.ADDITIONAL_INFORMATION,
    },
  ];

  const SITE_MODELING_FORMS = [
    {
      step: 1,
      content: <ProjectInformationForSiteModeling {...formControls} />,
      title: SUBFORM_TITLES.PROJECT_INFORMATION,
    },
    {
      step: 2,
      content: (
        <AdditionalInformationForSiteModeling
          {...formControls}
          tierLevel={tierLevel}
          handleNewBuildPlans={handleNewBuildPlans}
          areNewBuildPlansRequired={areNewBuildPlansRequired}
        />
      ),
      title: SUBFORM_TITLES.ADDITIONAL_INFORMATION,
    },
  ];

  const MAX_FILL_FORMS = [
    {
      step: 1,
      content: <ProjectInformationForSiteModeling {...formControls} isMaxFill={true} />,
      title: SUBFORM_TITLES.PROJECT_INFORMATION,
    },
    {
      step: 2,
      content: <DesignInformation {...formControls} />,
      title: SUBFORM_TITLES.DESIGN_INFORMATION,
    },
    {
      step: 3,
      content: (
        <AdditionalInformationForSiteModeling
          {...formControls}
          tierLevel={tierLevel}
          handleNewBuildPlans={handleNewBuildPlans}
          areNewBuildPlansRequired={areNewBuildPlansRequired}
        />
      ),
      title: SUBFORM_TITLES.ADDITIONAL_INFORMATION,
    },
  ];

  const getFormForTierLevel = () => {
    if (tierLevel === TIER_LEVEL.sitePlan) {
      return SITE_PLAN_FORMS;
    } else if (tierLevel === TIER_LEVEL.trueUp) {
      return TRUE_UP_FORMS;
    } else if (tierLevel === TIER_LEVEL.planSet || tierLevel === TIER_LEVEL.planSetForLarge) {
      return PLAN_SET_FORMS;
    } else if (tierLevel === TIER_LEVEL.siteModeling) {
      return SITE_MODELING_FORMS;
    } else if (tierLevel === TIER_LEVEL.maxFill) {
      return MAX_FILL_FORMS;
    } else if (tierLevel === TIER_LEVEL.planSetForPrelim) {
      return PLAN_SET_FOR_PRELIM_FORMS;
    }
    return [];
  };

  const FORMS = getFormForTierLevel();

  useEffect(() => {
    const currentFormState =
      formStep === 1 ? 'started' : formStep === FORMS.length + 1 ? 'complete' : 'inProgress';
    setFormsState(currentFormState);
  }, [formStep, setFormsState, FORMS.length]);

  return (
    <Card className="FormWrapper">
      <div>
        <CustomStep
          steps={FORMS.length}
          step={formStep}
          title={FORMS.find((option) => option.step === formStep)?.title}
        />
        {FORMS.find((option) => option.step === formStep)?.content}
      </div>
      {formsState === 'complete' ? (
        <ActionButtons
          {...selectedDesignInfo}
          isDesignSelectionPage={isDesignSelectionPage}
          stepNumber={stepNumber}
          formsState={formsState}
          handleResponse={handleResponse}
          handleCancel={handleCancel}
          upgrade={upgrade}
        />
      ) : (
        <FormActionButtons
          {...selectedDesignInfo}
          setFormStep={setFormStep}
          formStep={formStep}
          form={FORMS}
          handleSubmit={handleSubmit}
          isSubmitButtonDisabled={isSubmitButtonDisabled}
          values={values}
          tierLevel={tierLevel}
          uploadComplete={uploadComplete}
          stepNumber={stepNumber}
          handleResponse={handleResponse}
          filesAlreadyStored={filesAlreadyStored}
          useExistingDesign={useExistingDesign}
          useExistingDesignForTrueUp={useExistingDesignForTrueUp}
          getFieldProps={getFieldProps}
          upgrade={upgrade}
        />
      )}
    </Card>
  );
};

export default FormWrapper;
