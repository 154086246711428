import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { Company, Subscription } from 'types';

import {
  addVatToCompany,
  createCompany,
  fetchCompany,
  fetchCompanyBillingInfo,
  fetchCompanyClientSecret,
  fetchCompanyExistsByName,
  fetchCompanyLogo,
  fetchCompanyMembers,
  fetchCompanySubcriptionInfo,
  fetchDSPCompanies,
  fetchUserCompany,
  updateCompany,
  updateCompanyBillingInfo,
  updateCompanyCreditCardInfo,
  updateCompanyLogo,
} from 'api/company/companyService';
import { fetchDefaultCategories } from 'api/companyCategories/companyCategoriesService';

import { ContactScaniflySupport } from 'components';

import { openNotification } from 'helpers/utils/openNotification';

import { firstAdminUserCreationRequested } from '../usersSlice';

const name = 'company';

type CompanyState = {
  company: Company | null;
  companyLogo: string | null;
  isLoading: boolean;
  isSubscriptionInfoLoading: boolean;
  error: string | null | undefined;
  companyWithUserExistsError: string | null;
  companyMembers: any;
  companyCustomerInfo: any;
  companyCCInfo: any;
  companyBillingInfo: any;
  companySubscriptionInfo: {
    credits: { amount: number };
    subscription: Subscription;
    companyId: string;
  } | null;
  subscriptionRefreshDate: string | null;
  companyClientSecret: any;
  isCheckingCompany: boolean;
  companyExistsError: string | undefined;
  allCompanies: [];
  defaultCategories: [];
  pricingTier: any;
  dspCompanies: { title: string; value: string }[];
};

export const companyRequested = createAsyncThunk(
  `${name}/companyRequested`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await fetchCompany(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const companyRequestedByName = createAsyncThunk(
  `${name}/companyRequestedByName`,
  async (name: string, { rejectWithValue }) => {
    try {
      const response = await fetchCompanyExistsByName(name);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const companyBillingInfoRequested = createAsyncThunk(
  `${name}/companyBillingInfoRequested`,
  async (customerId: string, { rejectWithValue }) => {
    try {
      const response = await fetchCompanyBillingInfo(customerId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const companySubscriptionInfoRequested = createAsyncThunk(
  `${name}/companySubscriptionInfoRequested`,
  async (companyId: string, { rejectWithValue }) => {
    try {
      const response = await fetchCompanySubcriptionInfo(companyId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const companyCreditCardInfoUpdated = createAsyncThunk(
  `${name}/companyCreditCardInfoUpdated`,
  async (
    { customerId, paymentMethodId }: { customerId: string; paymentMethodId: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await updateCompanyCreditCardInfo(customerId, paymentMethodId);
      dispatch(companyBillingInfoRequested(customerId));
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const companyBillingInfoUpdated = createAsyncThunk(
  `${name}/companyBillingInfoUpdated`,
  async (
    {
      customerId,
      newBillingInfo,
      toggleForm,
    }: { customerId: string; newBillingInfo: object; toggleForm: () => void },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await updateCompanyBillingInfo(customerId, newBillingInfo);
      dispatch(companyBillingInfoRequested(customerId));
      toggleForm();
      openNotification({
        type: 'success',
        title: 'Success!',
        text: 'The company billing information has been successfully updated!',
      });
      return response.data;
    } catch (error: any) {
      openNotification({
        type: 'error',
        title: 'Error!',
        text: <ContactScaniflySupport erroredAction="update your billing information" />,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

export const companyUpdated = createAsyncThunk(
  `${name}/companyUpdated`,
  async (
    { companyId, companyInfo }: { companyId: string; companyInfo: object },
    { rejectWithValue }
  ) => {
    try {
      const response = await updateCompany(companyId, companyInfo);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const companyCreated = createAsyncThunk(
  `${name}/companyCreated`,
  async (
    {
      companyData,
      user,
      token,
      onSuccess,
    }: {
      companyData: object;
      user: string;
      token: string;
      onSuccess: (result: any) => void;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await createCompany(companyData, token);
      dispatch(firstAdminUserCreationRequested({ user, token }));
      onSuccess(response.data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const companyVatAdded = createAsyncThunk(
  `${name}/companyVatAdded`,
  async (
    {
      companyId,
      vatNumber,
      token,
      onSuccess,
    }: {
      companyId: string;
      vatNumber: string;
      token: string;
      onSuccess: () => void;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await addVatToCompany(companyId, vatNumber, token);
      onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userCompanyRequested = createAsyncThunk(
  `${name}/userCompanyRequested`,
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchUserCompany();
      dispatch(companyLogoRequested());
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const companyLogoUpdated = createAsyncThunk(
  `${name}/companyLogoUpdated`,
  async (logo: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await updateCompanyLogo(logo);
      dispatch(companyLogoRequested());
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const companyLogoRequested = createAsyncThunk(
  `${name}/companyLogoRequested`,
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('accessToken');
      if (token) {
        const response = await fetchCompanyLogo(token);
        return response.data;
      } else {
        throw new Error('Could not find access token');
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const companyClientSecretRequested = createAsyncThunk(
  `${name}/companyClientSecretRequested`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchCompanyClientSecret();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const companyMembersRequested = createAsyncThunk(
  `${name}/companyMembersRequested`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchCompanyMembers();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const defaultCategoriesRequested = createAsyncThunk(
  `${name}/defaultCategoriesRequested`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchDefaultCategories();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const dspCompaniesRequested = createAsyncThunk(
  `${name}/dspCompaniesRequested`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchDSPCompanies();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const companySlice = createSlice({
  name,
  initialState: {
    company: null,
    companyLogo: null,
    isLoading: false,
    isSubscriptionInfoLoading: false,
    error: undefined,
    companyWithUserExistsError: null,
    companyMembers: null,
    companyCustomerInfo: null,
    companyCCInfo: null,
    companyBillingInfo: null,
    companySubscriptionInfo: null,
    subscriptionRefreshDate: null,
    companyClientSecret: null,
    isCheckingCompany: false,
    companyExistsError: undefined,
    allCompanies: [],
    defaultCategories: [],
    pricingTier: null,
    dspCompanies: [],
  } as CompanyState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(companyClientSecretRequested.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(companyClientSecretRequested.fulfilled, (state, { payload }) => {
      state.companyClientSecret = payload;
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(companyClientSecretRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
    });
    builder.addCase(companyBillingInfoRequested.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(companyBillingInfoRequested.fulfilled, (state, { payload }) => {
      state.companyBillingInfo = payload;
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(companyBillingInfoRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
    });
    builder.addCase(companySubscriptionInfoRequested.pending, (state) => {
      state.isSubscriptionInfoLoading = true;
    });
    builder.addCase(companySubscriptionInfoRequested.fulfilled, (state, { payload }) => {
      state.companySubscriptionInfo = payload;
      state.subscriptionRefreshDate = `${moment().format('MM/DD/YYYY hh:mm:ss A')}`;
      state.error = null;
      state.isSubscriptionInfoLoading = false;
    });
    builder.addCase(companySubscriptionInfoRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isSubscriptionInfoLoading = false;
    });
    builder.addCase(companyCreditCardInfoUpdated.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(companyCreditCardInfoUpdated.fulfilled, (state, { payload }) => {
      state.companyCCInfo = payload;
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(companyCreditCardInfoUpdated.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
    });
    builder.addCase(companyBillingInfoUpdated.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(companyBillingInfoUpdated.fulfilled, (state, { payload }) => {
      state.companyCustomerInfo = payload;
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(companyBillingInfoUpdated.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
    });
    builder.addCase(companyMembersRequested.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(companyMembersRequested.fulfilled, (state, { payload }) => {
      state.companyMembers = payload;
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(companyMembersRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
    });
    builder.addCase(companyRequested.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(companyRequested.fulfilled, (state, { payload }) => {
      state.company = payload;
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(companyRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
    });
    builder.addCase(companyRequestedByName.pending, (state) => {
      state.isCheckingCompany = true;
    });
    builder.addCase(companyRequestedByName.fulfilled, (state, { payload }) => {
      state.companyExistsError = payload
        ? 'A company with this name already exists. Please try a different option or contact Scanifly support for help.'
        : undefined;
      state.isCheckingCompany = false;
    });
    builder.addCase(companyRequestedByName.rejected, (state, { error }) => {
      state.companyExistsError = error.message;
      state.isCheckingCompany = false;
    });
    builder.addCase(userCompanyRequested.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userCompanyRequested.fulfilled, (state, { payload }) => {
      state.company = payload;
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(userCompanyRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
    });
    builder.addCase(companyUpdated.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(companyUpdated.fulfilled, (state, { payload }) => {
      state.company = payload;
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(companyUpdated.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
    });
    builder.addCase(companyCreated.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(companyCreated.fulfilled, (state, { payload }) => {
      state.company = payload;
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(companyCreated.rejected, (state, { payload }: { payload: any }) => {
      if (['Company', 'already exists'].every((i) => payload.message?.includes(i))) {
        state.companyWithUserExistsError = payload.message;
      } else {
        state.error = payload.message;
      }
      state.isLoading = false;
    });
    builder.addCase(companyVatAdded.fulfilled, (state) => {
      state.error = null;
    });
    builder.addCase(companyVatAdded.rejected, (state, { payload }: { payload: any }) => {
      state.error = payload.message;
    });
    builder.addCase(companyLogoRequested.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(companyLogoRequested.fulfilled, (state, { payload }) => {
      state.companyLogo = payload;
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(companyLogoRequested.rejected, (state, { payload }: { payload: any }) => {
      state.error = payload;
      state.isLoading = false;
    });
    builder.addCase(companyLogoUpdated.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(companyLogoUpdated.fulfilled, (state) => {
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(companyLogoUpdated.rejected, (state, { payload }: { payload: any }) => {
      state.error = payload;
      state.isLoading = false;
    });
    builder.addCase(defaultCategoriesRequested.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(defaultCategoriesRequested.fulfilled, (state, { payload }) => {
      state.defaultCategories = payload;
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(defaultCategoriesRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
    });
    builder.addCase(dspCompaniesRequested.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(dspCompaniesRequested.fulfilled, (state, { payload }) => {
      state.dspCompanies = payload;
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(dspCompaniesRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
    });
  },
});

export default companySlice.reducer;
