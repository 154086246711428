import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'antd';

import { companyMembersRequested } from 'state/slices/companySlice';

import { InviteOrChangeTeammatesPermissions } from 'components';

import { MODAL_PROPS } from 'helpers/constants/modals';
import { USER_STATUS } from 'helpers/constants/userStatus';
import usePermissions from 'helpers/hooks/usePermissions';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

import './TeamList.scss';
import Teammate from './Teammate';

const TeamList = () => {
  const { isAdmin } = usePermissions();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalClosedFromOutside, setModalClosedFromOutside] = useState(false);
  const dispatch = useDispatch();
  const { companyMembers, company } = useSelector((state) => state.company);
  const { currentUser } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(companyMembersRequested());
  }, [dispatch]);

  const renderTeamMates = () => {
    return companyMembers?.map((teammate) => {
      return <Teammate key={teammate.id} teammate={teammate} />;
    });
  };

  const handleModalOpen = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setModalClosedFromOutside(true);
  };

  return (
    <>
      <Modal visible={modalVisible} onCancel={handleModalClose} {...MODAL_PROPS}>
        <InviteOrChangeTeammatesPermissions
          handleModalClose={handleModalClose}
          modalClosedFromOutside={modalClosedFromOutside}
          setModalClosedFromOutside={setModalClosedFromOutside}
          companyId={company?.id}
        />
      </Modal>
      <div className="TeamList-Wrapper">
        {renderTeamMates()}
        {isAdmin && currentUser?.status !== USER_STATUS.FROZEN && (
          <button
            onClick={handleModalOpen}
            aria-label="add teammate"
            className="Teammate Teammate-New"
            data-testid="add-teammate"
          >
            <PlusIcon className="Teammate-New-Icon" />
          </button>
        )}
      </div>
    </>
  );
};

export default TeamList;
