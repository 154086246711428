/**
 * The key for the flag in LD can be upper/lower case as well as
 * use - or _ for spaces. But enter it here with camel case.
 *
 * `useFlagsWithOverride` will convert env variables to camel case to match.
 */
export enum ACCESS {
  DESIGN_SERVICES_PRELIM = 'designServicesPrelim',
  DESIGN_SERVICES_PRELIM_PLANSETS = 'designServicesPrelimPlansets',
  ENABLE_PROJECT_CATEGORY_REFACTOR = 'projectCategoryRefactor',
  IS_DYNAMIC_IMAGE_RESIZER_ENABLED = 'isDynamicImageResizerEnabled',
  S3D_V3 = 's3DV3',
  UPPY_LOG_TO_CONSOLE = 'uppyLogToConsole',
  UPPY_USE_TUS = 'uppyUseTus',
  REMOTE_DESIGN = 'remoteDesign',
  GOOGLE_DRIVE = 'googleDrive',
  MAINTENANCE_REPORT = 'maintenanceReport',
  SIMPLE_DESIGN = 'simpleDesign',
  DISPLAY_EXTERNAL_METADATA = 'displayExternalMetadata',
  /**
   * This feature flag makes the updated monthly losses available inside S3D Settings->Simulation
   * @see https://app.launchdarkly.com/projects/default/flags/simulation-settings-monthly-losses
   */
  SIMULATION_SETTINGS_MONTHLY_LOSSES = 'simulationSettingsMonthlyLosses',
}
