import { USER_STATUS } from 'helpers/constants/userStatus';

import { droneDataScoreFields } from './Projects/constants/droneDataScoreFields';

type Filter = Record<'text' | 'value', string>[];

export const ACT_STATUS_FILTERS: Filter = [
  {
    text: 'Active',
    value: USER_STATUS.ACTIVE,
  },
  {
    text: 'Inactive',
    value: USER_STATUS.INACTIVE,
  },
  {
    text: 'Frozen',
    value: USER_STATUS.FROZEN,
  },
];

export const ENGINE_FILTERS: Filter = [
  {
    text: 'Watt3d',
    value: 'watt3d',
  },
  {
    text: 'RC',
    value: 'rc',
  },
];

export const ERROR_FILTERS: Filter = [
  {
    text: 'Error 1',
    value: 'Error 1',
  },
  {
    text: 'Error 2',
    value: 'Error 2',
  },
  {
    text: 'Error 3',
    value: 'Error 3',
  },
];

export const STATUS_FILTERS: Filter = [
  {
    text: 'Draft',
    value: 'Draft',
  },
  {
    text: 'Processing',
    value: 'Processing',
  },
  {
    text: 'Processed',
    value: 'Processed',
  },
  {
    text: 'Remote',
    value: 'Remote',
  },
  {
    text: 'Error',
    value: 'Error',
  },
  {
    text: 'Deleted',
    value: 'Deleted',
  },
  {
    text: 'Replaced',
    value: 'Replaced',
  },
  {
    text: 'Deleted',
    value: 'Deleted',
  },
];

export const POSITION_FILTERS: Filter = [
  {
    text: 'Designer',
    value: 'designer',
  },
  {
    text: 'Surveyor',
    value: 'surveyor',
  },
  {
    text: 'Finance Procurement',
    value: 'financeProcurement',
  },
  {
    text: 'Sales-Marketing',
    value: 'salesMarketing',
  },
  {
    text: 'Operations Project Manager',
    value: 'operationsProjectManager',
  },
  {
    text: 'Installer',
    value: 'installer',
  },
  {
    text: 'CEO',
    value: 'executiveOwnerCEO',
  },
  {
    text: 'Third Party Engineer',
    value: 'thirdPartyEngineer',
  },
  {
    text: 'Drone Pilot',
    value: 'dronePilot',
  },
  {
    text: 'Maintenance Tech',
    value: 'maintenanceTech',
  },
  {
    text: 'Guest',
    value: 'guest',
  },
];

export const CSV_EXPORT_MESSAGE = 'The file is downloading';

export const COPY_TO_CLIPBOARD_MESSAGE = (item: string) => {
  return `${item} has been copied to your clipboard.`;
};

export const PROJECTS_TABLE_CSV_COLUMN_TITLES = [
  {
    title: 'Project Name',
    dataIndex: 'name',
  },
  {
    title: 'Date Uploaded',
    dataIndex: 'uploadComplete',
  },
  {
    title: 'Date Completed',
    dataIndex: 'completedDate',
  },
  {
    title: 'Project Type',
    dataIndex: 'type',
  },
  {
    title: 'Company Name',
    dataIndex: 'submittedFor',
  },
  {
    title: 'Designs URL',
    dataIndex: 'id',
  },
  {
    title: 'Status',
    dataIndex: 'statusDescription',
  },
  {
    title: 'Engine',
    dataIndex: 'engine',
  },
  {
    title: 'Address',
    dataIndex: 'address',
  },
  {
    title: 'User Who Uploaded',
    dataIndex: 'createdBy',
  },
];

export const ADMIN_USER_MANAGER_CSV_COLUMN_TITLES = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
  },
  {
    title: 'Company',
    dataIndex: 'company',
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
  },
  {
    title: 'Position',
    dataIndex: 'position',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
  },
  {
    title: 'User Type',
    dataIndex: 'roles',
  },
  {
    title: 'User Id',
    dataIndex: 'id',
  },
];

export const DDS_COLUMN_TITLES = droneDataScoreFields.map((item) => {
  const [field, title] = item;
  return {
    title: `${title} - DDS`,
    dataIndex: field,
  };
});

export const ROLES = {
  admin: 'admin',
  default: 'non-admin',
  scaniflyAdmin: 'Scanifly admin',
};

export const mockProjectData = (projectName: string) => ({
  projectName: `${projectName} (Copy)`,
  address: 'United States',
  latitude: 40.69274,
  longitude: -73.98743,
  engine: 'watt3d',
  companyId: '6318ffc60b4a935132fe0717',
  createdBy: 'hello@scanifly.com',
});
