import { SIZED_FOR_CATEGORIES } from 'screens/DesignServices/constants';
import * as Yup from 'yup';

import { noWhitespaces } from 'helpers/utils/formValidationHelpers';

import { FORM_CONTROLS } from '../constants';

export const sitePlanValidationSchema = Yup.object().shape({
  [FORM_CONTROLS.HOMEOWNER_NAME]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.AHJ]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.SIZED_FOR_CATEGORY]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.SIZED_FOR_VALUE]: Yup.string().when(FORM_CONTROLS.SIZED_FOR_CATEGORY, {
    is: (category) => category !== SIZED_FOR_CATEGORIES.none,
    then: (schema) => schema.required('*This field is required').test(...noWhitespaces),
  }),
});
