import { DESIGN_STATUS } from '@cpm/scanifly-shared-data';
import confirm from 'antd/lib/modal/confirm';
import fontSizes from 'helpers/constants/fontSizes';
import fontWeights from 'helpers/constants/fontWeights';
import { CONFIRM_PROPS } from 'helpers/constants/modals';
import { projectDesignsRoute } from 'helpers/constants/routes';
import { openNotification } from 'helpers/utils/openNotification';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SIZED_FOR_CATEGORIES } from 'screens/DesignServices/constants';
import { getAnnualUsage } from 'screens/Projects/CustomerInfo/CustomerInfoUsage/helpers';
import { designStatusUpdateRequested } from 'state/slices/designServices/designServicesQueueSlice';
import { projectDesignsRequested } from 'state/slices/projectDesignsSlice';
import { projectRequested } from 'state/slices/projectSlice';
import { AppDispatch, RootState } from 'state/store/store';
import styled from 'styled-components';
import { Project, ProjectDesignData, ServiceRequest } from 'types';
import {
  DesignServicesStatusTitle,
  DesignServicesStatusWrapper,
} from '../../DesignSelection/FormWrapper/components';
import PendingApprovalActionButtons from '../PendingApprovalActionButtons/PendingApprovalActionButtons';

const StyledOrderedList = styled.ol`
  font-size: ${fontSizes.mediumSmall};
  margin-bottom: 2rem;
`;

const StyledUnorderedList = styled.ul`
  font-size: ${fontSizes.mediumSmall};
  margin: 2rem 0;
`;

const StyledContainer = styled.div`
  font-size: ${fontSizes.mediumSmall};
  margin-left: 2.5rem;
`;

const StyledInlineTitle = styled.span`
  font-weight: ${fontWeights.bold};
`;

type Props = {
  incompleteServiceRequest: ServiceRequest;
};

const getUnit = (sizedForCategory: keyof typeof SIZED_FOR_CATEGORIES) => {
  if (
    sizedForCategory === SIZED_FOR_CATEGORIES.production ||
    sizedForCategory === SIZED_FOR_CATEGORIES.consumption
  ) {
    return 'kWh';
  } else if (sizedForCategory === SIZED_FOR_CATEGORIES.systemSize) {
    return 'kW';
  }
  return '';
};

const getSelectedDesignValues = (selectedDesignToUse: string) => {
  const dataArr = selectedDesignToUse.split(', ');
  const productionVal = dataArr[2].split(' ')[2];
  const consumptionVal = dataArr[3].split(' ')[2];
  const systemSizeVal = dataArr[4].split(' ')[2];
  return {
    [SIZED_FOR_CATEGORIES.production]: productionVal ?? 0,
    [SIZED_FOR_CATEGORIES.consumption]: consumptionVal ?? 0,
    [SIZED_FOR_CATEGORIES.systemSize]: systemSizeVal ?? 0,
    [SIZED_FOR_CATEGORIES.none]: 0,
  };
};

const getSizedForValueForCategory = (projectDesign: ProjectDesignData, project: Project | null) => {
  const annualConsumptionValue = project
    ? getAnnualUsage(
        project.kwh.some((usage: number) => usage > 0) ? project.kwh : project.monthlyUsage
      )
    : 0;

  return {
    [SIZED_FOR_CATEGORIES.production]: Math.round(
      projectDesign?.annualProduction ?? 0
    ).toLocaleString(),
    [SIZED_FOR_CATEGORIES.consumption]: Math.round(annualConsumptionValue).toLocaleString(),
    [SIZED_FOR_CATEGORIES.systemSize]: (projectDesign?.systemSize ?? 0).toFixed(3),
    [SIZED_FOR_CATEGORIES.none]: 0,
  };
};

const DesignPendingApproval = ({ incompleteServiceRequest }: Props) => {
  const { t } = useTranslation();
  const { sizedForCategory, negativeTolerance, positiveTolerance, selectedDesignToUse } =
    incompleteServiceRequest.form;
  const { projectDesigns } = useSelector((state: RootState) => state.projectDesigns);
  const { project } = useSelector((state: RootState) => state.project);
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const projectId = incompleteServiceRequest.project.id;

  useEffect(() => {
    if (!projectDesigns || projectDesigns[0]?.projectId !== projectId) {
      dispatch(projectDesignsRequested(projectId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ((projectDesigns && !project) || projectDesigns[0]?.projectId !== projectId) {
      dispatch(projectRequested(projectId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuccess = useCallback(() => {
    openNotification({
      type: 'success',
      title: t('DesignStatusDisplay.acceptDesignSuccessNotificationTitle'),
      text: t('DesignStatusDisplay.acceptDesignSuccessNotificationContent'),
    });
    history.push(projectDesignsRoute(projectId));
  }, [history, projectId, t]);

  const handleDesignComplete = useCallback(() => {
    dispatch(
      designStatusUpdateRequested({
        designId: incompleteServiceRequest.id,
        projectName: incompleteServiceRequest.project.name,
        targetStatus: DESIGN_STATUS.completed,
        onSuccess,
      })
    );
  }, [dispatch, incompleteServiceRequest.id, incompleteServiceRequest.project.name, onSuccess]);

  const handleAccept = useCallback(() => {
    confirm({
      title: t('DesignStatusDisplay.acceptDesign'),
      content: t('DesignStatusDisplay.confirmAcceptDesign'),
      okText: t('Generic.yes'),
      onOk: handleDesignComplete,
      ...CONFIRM_PROPS,
    });
  }, [handleDesignComplete, t]);

  return (
    <DesignServicesStatusWrapper>
      <DesignServicesStatusTitle>
        {t('DesignStatusDisplay.pendingApprovalTitle')}
      </DesignServicesStatusTitle>
      <StyledUnorderedList>
        <li>
          {t('DesignStatusDisplay.projectOutOfTolerance', {
            sizedForCategory,
            negativeTolerance,
            positiveTolerance,
          })}
        </li>
        {selectedDesignToUse && sizedForCategory !== SIZED_FOR_CATEGORIES.none ? (
          <li>
            {t('DesignStatusDisplay.sizedForData', {
              sizedForCategory,
              unit: getUnit(sizedForCategory),
              initialProductionValue:
                getSelectedDesignValues(selectedDesignToUse)[
                  sizedForCategory as keyof typeof SIZED_FOR_CATEGORIES
                ],
              newProductionValue: getSizedForValueForCategory(projectDesigns[0], project)[
                sizedForCategory as keyof typeof SIZED_FOR_CATEGORIES
              ],
            })}
          </li>
        ) : null}
      </StyledUnorderedList>
      <h4>{t('DesignStatusDisplay.nextSteps')}</h4>
      <StyledOrderedList>
        <li>{t('DesignStatusDisplay.reviewYourDesign')}</li>
        <li>{t('DesignStatusDisplay.communicateWithDesigner')}</li>
      </StyledOrderedList>
      <StyledContainer>
        <StyledInlineTitle>{t('DesignStatusDisplay.helpfulTip')}</StyledInlineTitle>
        {t('DesignStatusDisplay.tipText')}
      </StyledContainer>
      <PendingApprovalActionButtons handleAcceptDesign={handleAccept} projectId={projectId} />
    </DesignServicesStatusWrapper>
  );
};

export default DesignPendingApproval;
