import { newWeights, oldWeights } from 'helpers/constants/weights';
import { i18n } from 'helpers/utils/translations';

export const getTooltipContent = (completedAfterDDSv2Release: boolean) => {
  const weights = completedAfterDDSv2Release ? newWeights : oldWeights;
  return (
    <div className="DroneDataScore-Tooltip">
      <p>{i18n.t('DroneDataScore.tooltipTitle')}</p>
      <p>{i18n.t('DroneDataScore.generalDescription')}</p>
      <ul>
        <li>
          {i18n.t('DroneDataScore.flightPathDescription', { flightPathWeight: weights.flightPath })}
        </li>
        <li>
          {i18n.t('DroneDataScore.cameraTechniqueDescription', {
            cameraTechniqueWeight: weights.cameraTechnique,
          })}
        </li>
        <li>
          {i18n.t('DroneDataScore.imageQualityDescription', {
            imageQualityWeight: weights.imageQuality,
          })}
        </li>
      </ul>
      <a
        href="https://help.scanifly.com/drone-data-score"
        target="_blank"
        rel="noreferrer"
        id="dds-support-link"
      >
        {i18n.t('DroneDataScore.learnMore')}
      </a>
    </div>
  );
};
